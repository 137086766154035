import sortBy from 'lodash.sortby';
import isFilledIn from '../../utilities/isFilledIn';
import addFieldInfo from './addFieldInfo';
import applyRules from './applyRules';

const documentIsComplete = doc => {
  const { acknowledge_type, crew_acknowledged, fields, images = [] } = doc;
  if (!images.length) return true;

  const isAcknowledgedIfNeeded = acknowledge_type !== 'C' || crew_acknowledged;
  const isRequiredFieldsFilledIn = fields.every(field =>
    field._derived.isRequired ? isFilledIn(field.value) : true,
  );
  return isAcknowledgedIfNeeded && isRequiredFieldsFilledIn;
};

const addFieldError = (field, doc, errors) => ({
  ...field,
  _derived: {
    ...field._derived,
    error: errors.find(
      ({ documentId, fieldId }) =>
        documentId === doc.id && fieldId === field.id,
    ),
  },
});

// Decorates a document with a `_derived` object containing the following attrs:
// - `error` holds the first document-level (ie. not related to a specific field)
//   error found for this document.
// - `isComplete` is true if the document has all required fields filled in.
// - `isViewable` is true if the document has images & may be viewed by the current user.
// It also decorates each field in the `fields` array with a `_derived` object
// containing the following attrs:
// - `error` holds the first field-level error found for this field.
// - `fieldType` one of `utilities/constants/fieldTypes`
// - `isInitials` whether the field is an initials field
// - `isSignature` whether the field is a signature OR initials field (confusing)
// - `isValidated` whether the field is a validated signature or intitials field
// - `isDisabled` whether field is disabled
// - `isRequired` whether field is mandatory
// - `readOnlyTooltip` the tooltip text to show if the field is disabled
// Lastly it may also overwrite the field's `value` attribute if a rule requires it.
const getDecoratedDocument = (doc, errors, changedFieldId, offer = {}) => {
  // Decorate fields with fieldInfo & errors
  let fields = (doc.fields || [])
    .map(addFieldInfo)
    .map(field => addFieldError(field, doc, errors));
  doc = { ...doc, fields };
  // Now apply rules to fields and add to doc again
  fields = fields.map(field => applyRules(field, doc, changedFieldId));
  doc = { ...doc, fields };
  // NOTE The reason we did this in two steps is so that applyRules gets the
  // _derived data for every field in the doc.

  // Add document-level derived data
  const isI9andSubmitted =
    doc.i9_document && doc.start_plus_status !== 'not_signed';
  const _derived = {
    error: errors.find(
      ({ documentId, fieldId }) => !fieldId && documentId === doc.id,
    ),
    isComplete: documentIsComplete(doc),
    isViewable: (doc.images || []).length && !isI9andSubmitted,
  };
  doc = { ...doc, _derived };

  return doc;
};

// Takes the following arguments:
// - `docs` an array of undecorated documents
// - `errors` an optional array of error objects { documentId, fieldId, message }
// - `changedFieldId` an optional id specifying which field was just changed
// Returns a new array of documents with the following changes:
// - Documents are sorted by rank.
// - Documents are decorated with a `_derived` object.
// - Document fields are decorated with a `_derived` object.
// - Document fields may also have their value updated.
const decorateDocuments = (docs, errors = [], changedFieldId, offer = {}) => {
  const sortedDocs = sortBy(docs, 'rank');
  return sortedDocs.map(doc =>
    getDecoratedDocument(doc, errors || [], changedFieldId, offer),
  );
};

export default decorateDocuments;
