import React from 'react';
import { withStyles } from '@mui/styles';

// MaterialUI Components
import { Snackbar, Typography, Button } from '@mui/material';
import {
  CheckCircle as CheckIcon,
  Error as ErrorIcon,
} from '@mui/icons-material';

// Constants
import * as palette from '../shared/oldDocumentSignerUI/palette';

const styles = theme => ({
  snackbar: {
    background: '#ffffff !important',
    display: 'grid !important',
    gridTemplateColumns: 'repeat(8, 1fr)',
  },
  snackbarContent: {
    gridColumn: '2 / -1',
  },
  snackbarMessage: {
    gridColumn: '1 / 7',
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    color: 'black !important',
  },
  snackbarAction: {
    gridColumn: '7 / span 2',
    padding: 10,
    margin: 'auto',
  },
  successNotification: {
    gridColumn: '1 / 2',
    color: palette.success2 + ' !important',
    alignSelf: 'center',
  },
  error: {
    gridColumn: '1 / 2',
    alignSelf: 'center',
    color: theme.palette.error.main + ' !important',
  },
});

const getMessage = type => {
  switch (type) {
    case 'documentComplete':
      return "Success! You've completed all the required fields for this form. Click continue to move to the next form!";
    case 'documentError':
      return 'There are errors in a form. Expand the form in the sidebar for details.';
    default:
      return 'An unknown error occurred while performing your action. Please try again shortly.';
  }
};

const GrowlNotification = props => {
  const { isOpen, classes, onClose, onExited, onContinue, type } = props;
  const message = getMessage(type);
  const icon =
    type === 'documentComplete' ? (
      <CheckIcon className={classes.successNotification} />
    ) : (
      <ErrorIcon className={classes.error} />
    );
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      ContentProps={{
        classes: {
          root: classes.snackbar,
          message: classes.snackbarMessage,
          action: classes.snackbarAction,
        },
      }}
      open={isOpen}
      onClose={onClose}
      onExited={onExited}
      autoHideDuration={7000}
      message={[
        icon,
        <Typography className={classes.snackbarContent}>{message}</Typography>,
      ]}
      action={
        type === 'documentComplete' && (
          <Button onClick={onContinue} color="primary" variant="contained">
            Continue
          </Button>
        )
      }
      data-test-id="GrowlNotification-root"
    />
  );
};

export default withStyles(styles)(GrowlNotification);
