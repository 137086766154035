import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import DocumentPageSkeleton from './DocumentPageSkeleton';

const preloadedImages = {};

const useStyles = makeStyles({
  root: {
    display: 'block',
    height: 'auto',
    width: '100%',
  },
});

const preloadImageFromUrl = url => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onerror = reject;
    img.onload = () => resolve(url);
    img.src = url;
    // Attach image object to preloadedImages object to prevent it from being garbage collected
    preloadedImages[url] = img;
  });
};

const DocumentPageImage = ({
  onImageLoadError,
  imageUrl,
  pageHeight,
  pageWidth,
}) => {
  const classes = useStyles();
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const [imageLoadTryCount, setImageLoadTryCount] = useState(0);

  useEffect(() => {
    if (!imageUrl) return;

    setIsImageLoaded(false);
    const tryCount = imageLoadTryCount + 1;
    setImageLoadTryCount(tryCount);

    (async () => {
      try {
        await preloadImageFromUrl(imageUrl);
        setIsImageLoaded(true);
      } catch (err) {
        onImageLoadError(err, tryCount);
      }
    })();
  }, [imageUrl]); // eslint-disable-line react-hooks/exhaustive-deps

  const isPageReady = imageUrl && isImageLoaded;

  return isPageReady ? (
    <img
      className={classes.root}
      alt="Document page"
      draggable={false}
      height={pageHeight}
      src={imageUrl}
      width={pageWidth}
    />
  ) : (
    <DocumentPageSkeleton pageHeight={pageHeight} pageWidth={pageWidth} />
  );
};

export default DocumentPageImage;
