import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import DocumentPage from './DocumentPage';
import { PageInputKind } from '../types';

const FOCUSABLE_ELEMENT_TYPES =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

const useStyles = makeStyles({
  root: {
    '& *': {
      boxSizing: 'border-box',
    },
  },
  zoomContainer: {
    height: 'fit-content',
  },
  contentContainer: {
    width: '100%',
    padding: '1%',
  },
  spacer: {
    paddingTop: '1%',
  },
});

const focusElement = el => {
  const focusableElement = el.matches(FOCUSABLE_ELEMENT_TYPES)
    ? el
    : el.querySelector(FOCUSABLE_ELEMENT_TYPES);
  if (!focusableElement) return;
  focusableElement.focus({ preventScroll: true });
};

const DocumentPane = ({
  actor,
  className,
  isAutosignActive,
  onChangeInput,
  onDoubleClick,
  onFocusInput,
  onNeedPage,
  onPageLoadError,
  pageHeight,
  pages,
  pageWidth,
  scrollContainer,
  selectedInputId,
  zoom,
}) => {
  const classes = useStyles();
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [, setRequestedPageIndexes] = useState([]);

  useEffect(() => {
    pages.forEach((page, pageIndex) => {
      if (page.imageUrl) return;
      const distanceToPage = Math.abs(pageIndex - currentPageIndex);
      if (distanceToPage > 2) return;
      setRequestedPageIndexes(requestedPageIndexes => {
        if (requestedPageIndexes.includes(pageIndex))
          return requestedPageIndexes;
        onNeedPage(pageIndex);
        return requestedPageIndexes.concat(pageIndex);
      });
    });
  }, [pages]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!selectedInputId) return;
    const el = document.getElementById(`input-${selectedInputId}`);
    if (!el) return;
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    });
    focusElement(el);
  });

  if (pages.length === 0) return <div>...</div>;

  const getInputAfter = inputId => {
    const allInputs = pages.flatMap(({ inputs }) => inputs).filter(x => !!x);
    const inputIndex = allInputs.findIndex(({ id }) => id === inputId);
    return allInputs[inputIndex + 1];
  };

  const handleReachingInputMaxLength = inputId => {
    const nextInput = getInputAfter(inputId);
    if (!nextInput) return;
    const isNextInputText =
      nextInput.kind === PageInputKind.Number ||
      nextInput.kind === PageInputKind.Text;
    if (!isNextInputText) return;
    onFocusInput && onFocusInput(nextInput.id);
  };

  return (
    <div
      className={clsx(classes.root, className)}
      onDoubleClick={onDoubleClick}
    >
      <div
        className={classes.zoomContainer}
        style={{ width: `${zoom * 100}%` }}
      >
        <div className={classes.contentContainer}>
          {pages.map((page, i) => (
            <React.Fragment key={i}>
              {i > 0 && <div className={classes.spacer} />}
              <DocumentPage
                actor={actor}
                dataTid={'DocumentPane-page-' + (i + 1)}
                isAutosignActive={isAutosignActive}
                onBecomeCurrent={() => setCurrentPageIndex(i)}
                onChangeInput={onChangeInput}
                onFocusInput={onFocusInput}
                onImageLoadError={(err, tryCount) =>
                  onPageLoadError(i, err, tryCount)
                }
                onReachInputMaxLength={handleReachingInputMaxLength}
                page={page}
                pageHeight={pageHeight}
                pageWidth={pageWidth}
                scrollContainer={scrollContainer}
              />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DocumentPane;
