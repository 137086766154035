import React from 'react';
import { Select, MenuItem } from '@mui/material';
import { withStyles } from '@mui/styles';
import useDepartments from 'common/hooks/useDepartments';
import withRouteHelpers from 'common/hoc/withRouteHelpers';

const styles = theme => ({
  conditionSelect: {
    width: 200,
    display: 'inline-block',
    paddingRight: theme.spacing(2),
    top: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
});

const MultiDepartmentSelect = props => {
  const { onChange, classes, selectedDepartments, projectId } = props;
  const { data: departmentOptions = [] } = useDepartments({ projectId });
  const departmentIds = selectedDepartments.map(department => department.value);
  const handleChange = event => {
    onChange(
      departmentOptions.filter(departmentOption =>
        event.target.value.includes(departmentOption.id),
      ),
    );
  };

  return (
    <Select
      labelId="departments-multi-select"
      data-test-id="Departments-MultiSelect"
      multiple
      value={departmentIds}
      onChange={handleChange}
      className={classes.conditionSelect}
    >
      <MenuItem value="departments" disabled>
        Select Departments
      </MenuItem>
      {departmentOptions.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default withStyles(styles)(withRouteHelpers(MultiDepartmentSelect));
