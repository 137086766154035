import React from 'react';
import { makeStyles } from '@mui/styles';
import DocumentPageInput from './DocumentPageInput';

const useStyles = makeStyles({
  root: {
    display: 'block',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    userSelect: 'none',
    '& *': {
      fontSize: pageWidth => `${pageWidth / 110}px`, // Font size proportional to page width
      fontFamily: 'Roboto, Helvetica Neue, Helvetica, Arial, sans-serif',
    },
  },
});

const DocumentPageInputs = ({
  actor,
  isAutosignActive,
  onChangeInput,
  onFocusInput,
  onReachInputMaxLength,
  inputs,
  pageHeight,
  pageWidth,
}) => {
  const classes = useStyles(pageWidth);
  return (
    <svg
      className={classes.root}
      viewBox={`0 0 ${pageWidth} ${pageHeight}`}
      onDoubleClick={e => {
        // Only register double-clicks on background
        const isOnThisNode = e.target.isSameNode(e.currentTarget);
        if (!isOnThisNode) e.stopPropagation();
      }}
    >
      {inputs.map(input => (
        <g key={input.id} id={`input-${input.id}`}>
          <foreignObject
            height={input.height}
            width={input.width}
            x={input.left}
            y={input.top}
            style={{ overflow: 'visible' }}
          >
            <DocumentPageInput
              actor={actor}
              input={input}
              isAutosignActive={isAutosignActive}
              onChange={value => onChangeInput(input.id, value)}
              onFocus={() => onFocusInput(input.id)}
              onReachMaxLength={() => onReachInputMaxLength(input.id)}
            />
          </foreignObject>
        </g>
      ))}
    </svg>
  );
};

export default DocumentPageInputs;
