import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { Button, Checkbox, Drawer, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';

const styles = theme => ({
  root: {
    boxSizing: 'border-box',
    width: '100%',
    maxWidth: '100%',
    padding: theme.spacing(2.2),
    background: theme.palette.bottomDialogBackground,
  },
  closeButton: {
    position: 'absolute !important',
    right: theme.spacing(1.2) + ' !important',
    top: theme.spacing(1.2) + ' !important',
    width: 50,
    color: theme.palette.primary.contrastText + ' !important',
  },
  primaryButton: {
    display: 'block',
    marginTop: theme.spacing(2),
  },
  description: {
    marginRight: theme.spacing(1.5),
    color: theme.palette.primary.contrastText,
    maxHeight: 75,
    overflow: 'auto',
  },
  header: {
    color: theme.palette.primary.contrastText,
    textTransform: 'capitalize',
  },
});

const DEFAULT_ACKNOWLEDGMENT_TITLE = 'Acknowledgement of Receipt';
const DEFAULT_ACKNOWLEDGMENT_DESCRIPTION =
  'I acknowledge receipt of, and agree to read and adhere to, the policies and procedures outlined in the document.';

class ClickThroughAcknowledgment extends Component {
  state = {
    checked: false,
  };

  onCheck = ({ target: { checked } }) => this.setState({ checked });

  render() {
    const {
      classes,
      isOpen,
      title,
      description,
      onAcknowledge,
      onClose,
    } = this.props;
    const { checked } = this.state;
    return (
      <Drawer open={isOpen} anchor="bottom">
        <div className={classes.root}>
          <Typography
            variant="h5"
            gutterBottom
            className={classes.header}
            data-test-id="ClickThroughAcknowledgment-header"
          >
            {title || DEFAULT_ACKNOWLEDGMENT_TITLE}
          </Typography>
          <Button
            className={classes.closeButton}
            onClick={onClose}
            tabIndex="-1"
            data-test-id="ClickThroughAcknowledgment-closeButton"
          >
            <Close />
          </Button>
          <Typography component="p" className={classes.description}>
            <Checkbox
              onChange={this.onCheck}
              data-test-id="ClickThroughAcknowledgment-checkbox"
              sx={{ color: '#fff !important' }}
            />{' '}
            {description || DEFAULT_ACKNOWLEDGMENT_DESCRIPTION}
          </Typography>
          <Button
            color="primary"
            variant="contained"
            className={classes.primaryButton}
            onClick={onAcknowledge}
            disabled={!checked}
            data-test-id="ClickThroughAcknowledgment-submitButton"
          >
            Submit Acknowledgment
          </Button>
        </div>
      </Drawer>
    );
  }
}

export default withStyles(styles)(ClickThroughAcknowledgment);
