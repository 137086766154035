import React from 'react';

import { withStyles } from '@mui/styles';
import { Select } from '@mui/material';
import { MenuItem } from '@mui/material';
import classNames from 'classnames';
import sharedCss from '../../shared/oldDocumentSignerUI/sharedCss';
import fieldStatusClass from './fieldStatusClass';
import { styled } from '@mui/system';

const styles = {
  ...sharedCss.fields,
};

const StyledSelect = styled(Select)({
  position: 'absolute',
});

const Dropdown = props => {
  const {
    id,
    name,
    x,
    y,
    height,
    width,
    handleChange,
    classes,
    options,
    setFieldRef,
    value = '',
    _derived: { isRequired, isDisabled },
  } = props;

  const fieldDynamicStyle = {
    top: y,
    left: x,
    height,
    width,
  };

  const dropdownOptions = Object.keys(options)
    .sort()
    .map(optionValue => (
      <MenuItem
        key={`${name}-${options[optionValue]}`}
        value={optionValue}
        data-test-id={`Dropdown-option-${id}/${optionValue}`}
        dense
      >
        {options[optionValue]}
      </MenuItem>
    ));
  dropdownOptions.unshift(
    <MenuItem
      key={`Dropdown-defaultNone-${name}`}
      value=""
      data-test-id={`Dropdown-defaultNone-${name}`}
      dense
    >
      None
    </MenuItem>,
  );
  return (
    <StyledSelect
      variant="standard"
      disableUnderline
      inputRef={e => setFieldRef(e, y)}
      placeholder={isRequired ? 'Required' : ''}
      id={name}
      value={value}
      className={classNames(classes.dropdown, fieldStatusClass(props))}
      style={fieldDynamicStyle}
      onChange={e => handleChange(e.target.value)}
      data-test-id={`Dropdown-root-${id}`}
      disabled={isDisabled}
    >
      {dropdownOptions}
    </StyledSelect>
  );
};

export default withStyles(styles)(Dropdown);
