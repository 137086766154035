import React from 'react';
import { makeStyles } from '@mui/styles';

var useStyles = makeStyles({
  root: {
    border: 0,
    color: 'black',
    height: '100%',
    padding: 0,
    paddingLeft: '1%',
    width: '100%',
  },
});

const CustomDateInput = props => {
  const {
    fieldId,
    onChange,
    value,
    height,
    width,
    onFocus,
    statusClass,
    dateFormatter,
  } = props;
  const fieldDynamicStyle = { height, width };
  const classes = useStyles();
  const className = statusClass + ' ' + classes.root;

  return (
    <div className={classes.container} style={fieldDynamicStyle}>
      <input
        value={value}
        style={fieldDynamicStyle}
        type="text"
        onChange={onChange}
        className={className}
        onFocus={() => onFocus()}
        placeholder={dateFormatter ? dateFormatter : 'MM/DD/YYYY'}
        data-tid={`DocumentPageNumberInput-input-${fieldId}`}
        margin="none"
      />
    </div>
  );
};

export default CustomDateInput;
