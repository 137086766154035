import React from 'react';
import { makeStyles } from '@mui/styles';
import useStatusClass from './useStatusClass';
import { PageInputStatus } from '../../types';

const useStyles = makeStyles({
  root: {
    border: 0,
    color: 'black',
    height: '100%',
    padding: 0,
    paddingLeft: '1%',
    width: '100%',
  },
});

const DocumentPageNumberInput = ({
  onChange,
  onFocus,
  onReachMaxLength,
  input,
}) => {
  const { id, status, value } = input;
  const isDisabled = status === PageInputStatus.Disabled;
  const classes = useStyles();
  const statusClass = useStatusClass(status);

  const handleChangedValue = value => {
    // Enforce numeric
    const isPotentiallyNumeric = value.match(/^(\d|-|\.)+$/g);
    if (value && !isPotentiallyNumeric) return;

    // Enforce maxLength
    if (input.maxLength != null) {
      if (value.length === input.maxLength) {
        onReachMaxLength();
      } else if (value.length > input.maxLength) {
        value = input.value ?? '';
      }
    }

    onChange(value);
  };

  const className = `${statusClass} ${classes.root}`;

  return isDisabled ? (
    <div className={className}>
      {value !== null && value !== undefined ? value : ''}
    </div>
  ) : (
    // WORKAROUND: Not using the native number input here because I cannot reliably
    // get rid of the spin buttons on every browser.
    // NOTE: `inputMode="decimal"` makes the number pad display on iOS:
    // https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/inputmode
    <input
      className={className}
      data-tid={`DocumentPageNumberInput-input-${id}`}
      inputMode="decimal"
      onChange={e => handleChangedValue(e.target.value)}
      onFocus={() => onFocus()}
      type="text"
      value={value !== null && value !== undefined ? value : ''}
    />
  );
};

export default DocumentPageNumberInput;
