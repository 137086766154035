import React from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, TextField as MuiTextField } from '@mui/material';
import useStatusClass from './useStatusClass';
import MobilePrintNameIcon from 'common/icons/MobilePrintNameIcon';

const useStyles = makeStyles({
  root: {
    border: '0 !important',
    color: 'black !important',
    height: '100% !important',
    padding: '0 !important',
    paddingLeft: '1% !important',
    width: '100% !important',
  },
  printNameContainer: {
    display: 'flex',
  },
  printNameButton: {
    position: 'absolute !important',
    right: '5px !important',
    bottom: '10px !important',
    minWidth: '70px !important',
    width: '70px !important',
    padding: '5px !important',
    background: '#ff0000 !important',
    borderRadius: '3px !important',
    opacity: '0.7 !important',
    '&:hover': {
      background: '#dd0000 !important',
    },
  },
});

const DocumentPagePrintNameInput = ({ actor, onFocus, onChange, input }) => {
  const { status, value } = input;
  const classes = useStyles();
  const { name } = actor;
  const handlePrintNameButtonClick = () => {
    onChange(name);
  };

  const statusClass = useStatusClass(status);
  const className = `${statusClass} ${classes.root} ${classes.printNameContainer}`;
  const isValuePresent = !!value;
  return (
    <div>
      <MuiTextField
        type="text"
        className={className}
        onFocus={() => onFocus()}
        value={isValuePresent ? value : ''}
        disabled={true}
      />

      {!isValuePresent && (
        <IconButton
          className={classes.printNameButton}
          onClick={() => handlePrintNameButtonClick()}
        >
          <MobilePrintNameIcon />
        </IconButton>
      )}
    </div>
  );
};

export default DocumentPagePrintNameInput;
