import { compose } from 'redux';
import React, { useState } from 'react';
import { graphql } from 'react-apollo';
import { withStyles } from '@mui/styles';
import { Button, IconButton } from '@mui/material';
import {
  AddCircleOutline as AddIcon,
  Delete as DeleteIcon,
  FilterNone as DuplicateIcon,
  ArrowForward as ArrowIcon,
} from '@mui/icons-material';
import Select from 'react-select';
import MultiDepartmentSelect from './MultiDepartmentSelect';
import ProjectTemplateWorkflowLevel from './ProjectTemplateWorkflowLevel';
import ProjectTemplateWorkflowApproversSelector from './ProjectTemplateWorkflowApproversSelector';
import * as Queries from 'common/apollo/queries';
import withRouteHelpers from 'common/hoc/withRouteHelpers';
import * as colors from 'common/components/CnCUi/theme/colors';

const DEFAULT_CONDITION = { label: 'Default', value: null };
const OVERSCALE_CONDITION = {
  label: 'Overscale Rate',
  value: 'overscale_rate',
  type: 'overscale',
};
const DEPARTMENTS_CONDITION = {
  label: 'Departments',
  value: 'departments',
  type: 'departments',
};

const reactSelectStyles = {
  control: (styles, state) => ({
    ...styles,
    '&:hover': {
      borderColor: '#0000FF',
    },
    borderColor:
      state.isActive || state.isFocused ? '#0000FF' : styles.borderColor,
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isSelected
      ? '#0000FF'
      : state.isFocused
      ? '#E2F1FF'
      : styles.backgroundColor,
  }),
};

const styles = theme => ({
  row: {
    display: 'flex',
    marginBottom: theme.spacing(4),
    width: '100%',
  },
  rowIndex: {
    alignSelf: 'center',
    textAlign: 'center',
    fontSize: 'x-large',
    width: 40,
    height: 40,
    color: '#0000B3',
    border: '1px solid #0000B3',
    borderRadius: 20,
    padding: '9px 0 0 0',
    marginRight: theme.spacing(2),
    flexShrink: 0,
  },
  rowBody: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    background: '#E2F1FF',
    padding: theme.spacing(2),
    overflowX: 'scroll',
  },
  rowControls: {
    paddingBottom: theme.spacing(2),
  },
  rowLevels: {
    display: 'flex',
    height: 340,
  },
  conditionSelect: {
    width: 300,
    display: 'inline-block',
    marginRight: theme.spacing(2),
  },
  outlinedButton: {
    borderRadius: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontSize: theme.spacing(1.7),
    textTransform: 'Capitalize',
    border: `1px solid #0000FF`,
    color: '#0000FF',
    '&:hover': {
      border: '1px solid #1A55FD',
    },
  },
  containedButton: {
    borderRadius: 0,
    fontSize: theme.spacing(1.7),
    textTransform: 'Capitalize',
    color: '#FFFFFF',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    background: '#0000FF',
    '&:hover': {
      background: '#1A55FD',
    },
    '&:active': {
      background: '#0000B3',
    },
  },
  addLevelContainer: {
    paddingTop: 60,
    border: '2px dashed grey',
    width: 250,
    height: '100%',
    textAlign: 'center',
    background: 'white',
    flexShrink: 0,
  },
  deleteWorkflowContainer: {
    alignSelf: 'center',
    padding: theme.spacing(0, 2, 0, 2),
  },
  arrow: {
    alignSelf: 'center',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: colors.GRAY,
  },
});

const ProjectTemplateWorkflow = props => {
  const {
    classes,
    departments,
    index,
    isTemplateI9,
    onChange,
    onDelete,
    onDuplicate,
    templateRoles,
    templateRules,
    workflow,
  } = props;
  const {
    id,
    departments: workflowDepartments,
    rule,
    levels,
    overscaleConditionTriggered,
  } = workflow;
  const ruleGroupOptions = templateRules.map(({ id, name }) => ({
    value: id,
    label: name,
    type: 'rule',
  }));
  const departmentGroupOptions = departments.map(({ id, name }) => ({
    value: id,
    label: name,
    type: 'departments',
  }));
  const conditionSelectOptions = [
    { label: null, options: [DEFAULT_CONDITION, OVERSCALE_CONDITION] },
    { label: null, options: [DEPARTMENTS_CONDITION] },
    { label: 'Rules', options: ruleGroupOptions },
  ];
  const departmentsConditionSelectOptions = [
    { label: 'Departments', options: departmentGroupOptions },
  ];
  const conditionSelectValues =
    (rule && { value: rule.id, label: rule.name }) ||
    (workflowDepartments?.length && DEPARTMENTS_CONDITION) ||
    (overscaleConditionTriggered && OVERSCALE_CONDITION) ||
    DEFAULT_CONDITION;
  const selectedDepartments = workflowDepartments?.map(department => ({
    value: department.id,
    label: department.name,
  }));
  const [isApproversDialogOpen, setIsApproversDialogOpen] = useState(false);
  const isDepartmentsOpen = conditionSelectValues.label === 'Departments';

  const getNewLevelName = () => {
    if (!levels.length) return 'Level 1';
    const lastLevel = levels[levels.length - 1];
    const lastLevelNumber =
      parseInt((/Level ([0-9])+/.exec(lastLevel.name) || [])[0]) ||
      levels.length;
    return `Level ${lastLevelNumber + 1}`;
  };

  const addLevel = approvers => {
    const newLevel = {
      name: getNewLevelName(),
      role: null,
      users: approvers,
    };
    onChange({ ...workflow, levels: levels.concat(newLevel) });
  };

  const updateLevel = (index, updatedLevel) => {
    const newLevels = levels.map((level, i) =>
      i === index ? updatedLevel : level,
    );
    onChange({ ...workflow, levels: newLevels });
  };

  const deleteLevel = index => {
    const newLevels = levels.filter((__, i) => i !== index);
    onChange({ ...workflow, levels: newLevels });
  };

  const updateSelectCondition = selectOption => {
    const { value, label, type } = selectOption;
    const patch = (() => {
      switch (type) {
        case 'rule': {
          return {
            departments: [],
            overscaleConditionTriggered: false,
            rule: { id: value, name: label },
          };
        }
        case 'departments': {
          return {
            departments: [
              ...(workflowDepartments || []),
              { id: value, name: label },
            ],
            overscaleConditionTriggered: false,
            rule: null,
          };
        }
        case 'overscale': {
          return {
            departments: [],
            overscaleConditionTriggered: true,
            rule: null,
          };
        }
        default: {
          return {
            departments: [],
            overscaleConditionTriggered: false,
            rule: null,
          };
        }
      }
    })();

    onChange({ ...workflow, ...patch });
  };

  const updateSelectedDepartmentIds = departments => {
    onChange({ ...workflow, departments });
  };

  return (
    <div
      className={classes.row}
      data-test-id={`ProjectTemplateWorkflow-root-${id}`}
    >
      <div
        className={classes.rowIndex}
        data-test-id="ProjectTemplateWorkflow-index"
      >
        {index + 1}
      </div>
      <div className={classes.rowBody}>
        <div className={classes.rowControls}>
          <span data-test-id="ProjectTemplateWorkflow-condition">
            <Select
              options={conditionSelectOptions}
              className={classes.conditionSelect}
              value={conditionSelectValues}
              onChange={updateSelectCondition}
              styles={reactSelectStyles}
            />
          </span>
          {isDepartmentsOpen && (
            <span data-test-id="ProjectTemplateWorkflow-departmentsCondition">
              <MultiDepartmentSelect
                options={departmentsConditionSelectOptions}
                className={classes.conditionSelect}
                selectedDepartments={selectedDepartments}
                onChange={updateSelectedDepartmentIds}
              />
            </span>
          )}
          <Button
            variant="outlined"
            data-test-id="ProjectTemplateWorkflow-duplicate"
            onClick={onDuplicate}
            className={classes.outlinedButton}
            startIcon={<DuplicateIcon />}
          >
            Duplicate
          </Button>
        </div>
        <div className={classes.rowLevels}>
          {levels.map((level, index) => (
            <React.Fragment>
              <ProjectTemplateWorkflowLevel
                level={level}
                index={index}
                key={index}
                onChange={updatedLevel => updateLevel(index, updatedLevel)}
                onDelete={() => deleteLevel(index)}
                templateRoles={templateRoles}
                isTemplateI9={isTemplateI9}
              />
              <ArrowIcon fontSize="large" className={classes.arrow} />
            </React.Fragment>
          ))}
          <div className={classes.addLevelContainer}>
            <Button
              variant="contained"
              data-test-id="ProjectTemplateWorkflow-addLevel"
              onClick={() => setIsApproversDialogOpen(true)}
              className={classes.containedButton}
              startIcon={<AddIcon />}
            >
              Add Level
            </Button>
            {isApproversDialogOpen && (
              <ProjectTemplateWorkflowApproversSelector
                isTemplateI9={isTemplateI9}
                open={isApproversDialogOpen}
                onClose={() => setIsApproversDialogOpen(false)}
                onSubmit={addLevel}
              />
            )}
          </div>
          <div className={classes.deleteWorkflowContainer}>
            <IconButton
              onClick={onDelete}
              data-test-id="ProjectTemplateWorkflow-delete"
            >
              <DeleteIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

const withDepartments = graphql(Queries.GetDepartments, {
  options: ({ projectId }) => ({
    variables: { projectId },
  }),
  props: ({ data: { departments = [] } = {} }) => ({
    departments,
  }),
});

export default compose(
  withRouteHelpers,
  withDepartments,
  withStyles(styles),
)(ProjectTemplateWorkflow);
