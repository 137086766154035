import React from 'react';
import { styled } from '@mui/system';
import MuiTextField from '@mui/material/TextField';
import truncate from '../../utilities/truncate';
import isFilledIn from '../../utilities/isFilledIn';
import * as palette from '../../shared/oldDocumentSignerUI/palette';

// Define the styles directly using styled-components
const TextFieldWrapper = styled('div')(({ x, y, height, width }) => ({
  position: 'absolute',
  top: y,
  left: x,
  height,
  width,
}));

const StyledTextField = styled(MuiTextField)(({ status }) => ({
  position: 'absolute',
  width: '100%',
  '& .MuiInputBase-input': {
    paddingLeft: '5px',
    paddingRight: '5px',
    ...(status === 'error' && {
      backgroundColor: palette.transparentRed,
    }),
    ...(status === 'success' && {
      backgroundColor: palette.transparentGreen,
    }),
    ...(status === 'required' && {
      backgroundColor: palette.transparentYellow,
    }),
    ...(status === 'readOnly' && {
      color: palette.black,
    }),
    ...(status === 'optional' && {
      backgroundColor: palette.transparentBlue,
    }),
  },
}));

const determineFieldStatus = ({
  value,
  _derived: { error, isDisabled, isRequired },
}) => {
  if (error) return 'error';
  if (isRequired) {
    if (isFilledIn(value)) return 'success';
    return 'required';
  }
  if (isDisabled) return 'readOnly';
  return 'optional';
};

const TextField = props => {
  const {
    name,
    x,
    y,
    height,
    width,
    isPassword,
    isMultiline,
    handleChange,
    value,
    setFieldRef,
    max,
    id,
    _derived: { isRequired, isDisabled },
  } = props;

  const fieldStatus = determineFieldStatus(props);

  return (
    <TextFieldWrapper x={x} y={y} height={height} width={width}>
      <StyledTextField
        variant="standard"
        id={name}
        inputRef={e => setFieldRef(e, y)}
        placeholder={isRequired ? 'Required' : ''}
        value={value === null || value === undefined ? '' : value}
        multiline={isMultiline}
        autoComplete="new-password"
        type={isPassword ? 'password' : 'text'}
        onChange={e => handleChange(truncate(e.target.value, max))}
        margin="none"
        disabled={isDisabled}
        data-test-id={`TextField-root-${id}`}
        status={fieldStatus}
        InputProps={{
          disableUnderline: isDisabled,
          inputProps: {
            'data-test-id': `TextField-input-${id}`,
          },
        }}
      />
    </TextFieldWrapper>
  );
};

TextField.defaultProps = {
  isPassword: false,
  isMultiline: false,
};

export default TextField;
