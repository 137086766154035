import React from 'react';

import { withStyles } from '@mui/styles';
import { styled } from '@mui/system';
import { IconButton, TextField as MuiTextField } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { Clear as ClearIcon } from '@mui/icons-material';
import SignatureIcon from '../../icons/SignatureIcon';
import classNames from 'classnames';
import * as palette from '../../shared/oldDocumentSignerUI/palette';
import sharedCss from '../../shared/oldDocumentSignerUI/sharedCss';
import fieldStatusClass from './fieldStatusClass';

const styles = theme => ({
  root: {
    position: 'absolute',
  },
  '@font-face': {
    fontFamily: 'Caflischscriptpro',
    src: [
      "url('https://payrollplus-assets.castandcrew.com/caflischscriptpro-regular.otf')",
    ],
  },
  textField: {
    width: '100% !important',
    height: '100% !important',
  },
  editButton: {
    position: 'absolute !important',
    padding: '0 !important',
    right: '5px !important',
    bottom: '5px !important',
    background: 'transparent !important',
  },
  input: {
    fontFamily: 'Caflischscriptpro !important',
    padding: '0 !important',
    color: palette.darkGrey,
    paddingLeft: '5px',
    paddingRight: '5px',
    backgroundColor: '#EEFF0C4D !important',
  },
  signatureInput: {
    fontFamily: 'Caflischscriptpro !important',
    padding: '0 !important',
    color: '#333 !important',
    paddingLeft: '5px !important',
    paddingRight: '5px !important',
    backgroundColor: '#53FF0C4D !important',
    fontWeight: '400 !important',
  },
  ...sharedCss.fields,
});

const StyledIconButton = styled(IconButton)({
  position: 'absolute',
  right: 5,
  bottom: 10,
  minWidth: 70,
  width: 70,
  padding: 5,
  background: '#ff0000',
  borderRadius: 3,
  opacity: 0.7,
  '&:hover': {
    background: '#dd0000',
  },
});

const SignatureFieldBase = ({ isValidated }) => {
  const Component = props => {
    const {
      name,
      id,
      x,
      y,
      height,
      width,
      handleChange,
      classes,
      value,
      handleSignatureButtonClick,
      clearSignatureButtonClick,
      setFieldRef,
      _derived: { isRequired, isDisabled },
    } = props;

    const fieldStyle = {
      top: y,
      left: x,
      height,
      width,
      fontSize: height * 0.8,
    };

    const showButton = (!isValidated || !value) && !isDisabled;
    const mainInput = value ? classes.signatureInput : classes.input;
    const inputClass = classNames(mainInput, fieldStatusClass(props));

    return (
      <div
        className={classes.root}
        style={fieldStyle}
        data-test-id={`SignatureFieldBase-root-${id}`}
      >
        <MuiTextField
          id={name}
          variant="standard"
          inputRef={e => setFieldRef(e, y)}
          placeholder={isRequired ? 'Required' : ''}
          className={classes.textField}
          value={value === null || value === undefined ? '' : value}
          multiline={false}
          type="text"
          onChange={e => handleChange(e.target.value)}
          margin="none"
          disabled
          InputProps={{
            disableUnderline: false,
            classes: { input: inputClass },
            style: { fontSize: height * 0.8 },
          }}
        />
        {showButton && (
          <StyledIconButton
            className={value ? classes.editButton : classes.signatureButton}
            onClick={() => handleSignatureButtonClick(id)}
            data-test-id={`SignatureFieldBase-button-${id}`}
          >
            {value ? <EditIcon /> : <SignatureIcon />}
          </StyledIconButton>
        )}
        {!showButton && (
          <IconButton
            className={classes.editButton}
            onClick={() => clearSignatureButtonClick(id)}
            data-test-id={`SignatureClearFieldBase-button-${id}`}
          >
            <ClearIcon />
          </IconButton>
        )}
      </div>
    );
  };

  return withStyles(styles)(Component);
};

export default SignatureFieldBase;
