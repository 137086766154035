import React, { useState } from 'react';

import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import { Button, Typography, Menu, MenuItem } from '@mui/material';
import * as palette from '../shared/oldDocumentSignerUI/palette';
import DialogButton from '../DialogButton';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import useFeatureFlags from 'common/hooks/useFeatureFlags';

const styles = theme => ({
  root: {
    placeItems: 'center',
    display: 'flex',
    width: '100%',
    fontSize: '1rem',
  },
  buttonBase: {
    flex: 1,
    marginLeft: 5,
    width: '100%',
  },
  label: {
    color: '#FFFFFF',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontSize: '.73rem',
  },
  rejectButtonBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '16px',
  },
  removeDocButton: {
    color: '#FFFFFF',
    maxWidth: '28px !important',
    height: '27px',
    marginRight: '2px',
    padding: '14px 0px',
    minWidth: '28px !important',
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      height: '80%',
      width: '1px',
      backgroundColor: '#fff', // White line on the left side
    },
  },
  dropDownIcon: {
    color: '#FFFFFF',
  },
  inverseLabel: {
    color: palette.black,
  },
  save: {
    textTransform: 'none !important',
    height: '27px !important',
    paddingTop: '6px',
    paddingBottom: '6px',
    backgroundColor: '#FFFFFF',
    gridColumn: '1',
  },
  accept: {
    color: '#FFFFFF !important',
    height: '27px',
    margin: '3px !important',
    paddingTop: '6px',
    paddingBottom: '6px',
    textTransform: 'none !important',
    gridColumn: '2 ',
    background: '#00DF68 !important',
    '&:hover': {
      background: '#00DF68 !important',
    },
    '&:focus': {
      background: '#00DF68 !important',
    },
    '&:active': {
      background: '#00DF68 !important',
    },
    '&:disabled': {
      background: '#0000B3 !important',
    },
  },
  cancelButton: {
    color: '#FFFFFF !important',
    height: '27px',
    paddingTop: '6px',
    paddingBottom: '6px',
    textTransform: 'none !important',
    marginLeft: '3px',
  },
  documents: {
    display: 'inline',
    fontSize: '0.8em',
    fontWeight: 'bold',
    '& li': {
      float: 'left',
      display: 'inline',
      paddingRight: '10px',
    },
    '& li:before': {
      content: '"• "',
    },
  },
  confirmationCancelText: {
    textTransform: 'none',
    fontStyle: 'italic',
    fontWeight: '600 !important',
    fontSize: '13px !important',
    textDecoration: 'underline',
    color: '#161616 !important',
  },
  confirmationConfirmButton: {
    background: '#536881 !important',
    '&:hover': {
      background: '#0000B3 !important',
    },
    '&:focus': {
      background: '#0000B3 !important',
    },
    color: theme.palette.primary.contrastText,
  },
  confirmationConfirmText: {
    textTransform: 'none',
  },
});

const ActionButtons = props => {
  const {
    actions,
    actionButtonClick,
    classes,
    groupedAcknowledgmentDocumentNames,
    isOfferComplete,
    templatesAcknowledgeNotes,
    templatesAcknowledgeTitle,
    canRemoveDocument,
    isDocOne,
  } = props;
  const flags = useFeatureFlags();
  const isRemoveDocumentFlagActive = flags?.includes('RemoveDocument');
  const isRemoveDocument =
    isRemoveDocumentFlagActive && canRemoveDocument && !isDocOne;
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = event => setAnchorEl(event.currentTarget);

  const handleReject = () => {
    setAnchorEl(null);
    actionButtonClick('cancel');
  };

  const handleRemoveDocument = () => {
    setAnchorEl(null);
    actionButtonClick('remove');
  };

  const handleClose = () => setAnchorEl(null);

  return (
    <div className={classes.root}>
      {actions.save && (
        <DialogButton
          color="tertiary"
          variant="contained"
          className={classNames(classes.buttonBase, classes.save)}
          buttonClasses={{
            label: classNames(classes.inverseLabel),
          }}
          onClick={() => actionButtonClick('save')}
          dialogType="alert"
          dialogTitle="Your documents have been saved"
          dialogBody="Please note: Your offer is not complete and will not be submitted for approval until you complete and sign all offer documents. You can login and resume the process."
          data-test-id="ActionButtons-saveButton"
        >
          {actions.save.text}
        </DialogButton>
      )}
      {actions.submit && (
        <DialogButton
          variant="contained"
          className={classNames(classes.buttonBase, classes.accept)}
          buttonClasses={{ label: classes.label }}
          disabled={!isOfferComplete}
          onClick={() => actionButtonClick('submit')}
          dialogType={
            groupedAcknowledgmentDocumentNames.length && 'confirmation'
          }
          dialogTitle={templatesAcknowledgeTitle}
          dialogBody={
            <span>
              <Typography>{templatesAcknowledgeNotes}</Typography>
              <ul className={classes.documents}>
                {groupedAcknowledgmentDocumentNames.map(name => (
                  <li>{name}</li>
                ))}
              </ul>
            </span>
          }
          dialogCancelButton={
            <Button fullWidth>
              <span className={classes.confirmationCancelText}>
                On second thought, let me review those again...
              </span>
            </Button>
          }
          dialogConfirmButton={
            <Button
              fullWidth
              color="primary"
              variant="contained"
              className={classes.confirmationConfirmButton}
            >
              <span className={classes.confirmationConfirmText}>
                I acknowledge receipt of the aforementioned documents
              </span>
            </Button>
          }
          data-test-id="ActionButtons-submitButton"
        >
          {actions.submit.text}
        </DialogButton>
      )}
      {actions.cancel && (
        <Button
          color="secondary"
          className={
            !isRemoveDocument
              ? classNames(classes.buttonBase, classes.cancelButton)
              : classNames(classes.cancelButton)
          }
          classes={{ label: classes.label }}
          variant="contained"
          onClick={() => actionButtonClick('cancel')}
          data-test-id="ActionButtons-cancelButton"
        >
          {actions.cancel.text}
        </Button>
      )}
      {isRemoveDocument && (
        <Button
          variant="contained"
          color="secondary"
          classes={{ root: classes.removeDocButton }}
          onClick={handleMenu}
          aria-controls="simple-menu"
          aria-haspopup="true"
          data-test-id="ActionButtons-menuButton"
        >
          <ArrowDropDownIcon className={classNames(classes.dropDownIcon)} />
        </Button>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleReject}>Reject Offer</MenuItem>
        <MenuItem
          onClick={handleRemoveDocument}
          data-test-id="ActionButtons-removeDocumentButton"
        >
          Remove Document
        </MenuItem>
      </Menu>
    </div>
  );
};

export default withStyles(styles)(ActionButtons);
