import React from 'react';
import { makeStyles } from '@mui/styles';
import TodayIcon from '@mui/icons-material/Today';
import { format } from 'date-fns';
import useStatusClass from './useStatusClass';
import { PageInputStatus } from '../../types';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    border: 0,
    padding: 0,
  },
  icon: {
    float: 'right',
    marginRight: 5,
  },
  readout: {
    color: 'black',
    float: 'left',
    marginLeft: 5,
  },
});

const DocumentPageTimestampInput = ({ onChange, onFocus, input }) => {
  const { dateFormat, id, status, value } = input;
  const classes = useStyles();
  const statusClass = useStatusClass(status);

  return (
    <button
      className={`${statusClass} ${classes.root}`}
      data-tid={`DocumentPageTimestampInput-button-${id}`}
      disabled={status === PageInputStatus.Disabled}
      onClick={() => onChange(new Date())}
      onFocus={() => onFocus()}
    >
      <span className={classes.readout}>
        {value ? format(value, dateFormat || 'P') : '- click to set -'}
      </span>
      <TodayIcon className={classes.icon} />
    </button>
  );
};

export default DocumentPageTimestampInput;
