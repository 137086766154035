import React from 'react';

import { withStyles } from '@mui/styles';
import { IconButton, TextField as MuiTextField } from '@mui/material';
import PrintNameIcon from 'common/icons/PrintNameIcon';
import classNames from 'classnames';
import * as palette from 'common/shared/oldDocumentSignerUI/palette';
import sharedCss from 'common/shared/oldDocumentSignerUI/sharedCss';
import fieldStatusClass from './fieldStatusClass';

const styles = theme => ({
  root: {
    position: 'absolute !important',
  },
  '@font-face': {
    fontFamily: 'Caflischscriptpro',
    src: [
      "url('https://payrollplus-assets.castandcrew.com/caflischscriptpro-regular.otf')",
    ],
  },
  textField: {
    width: '100% !important',
    height: '100% !important',
  },
  printNameButton: {
    position: 'absolute !important',
    right: '5px !important',
    bottom: '10px !important',
    minWidth: '70px !important',
    width: '70px !important',
    padding: '5px !important',
    background: '#ff0000 !important',
    borderRadius: '3px !important',
    opacity: '0.7 !important',
    '&:hover': {
      background: '#dd0000 !important',
    },
  },
  input: {
    fontFamily: 'Caflischscriptpro !important',
    padding: '0px !important',
    color: palette.darkGrey,
    backgroundColor: '#EEFF0C4D !important',
    paddingLeft: '5px !important',
    paddingRight: '5px !important',
  },
  signatureInput: {
    fontFamily: 'Caflischscriptpro !important',
    padding: '0 !important',
    color: '#333 !important',
    backgroundColor: '#53FF0C4D !important',
    paddingLeft: '5px !important',
    paddingRight: '5px !important',
    fontWeight: '400 !important',
  },
  ...sharedCss.fields,
});

const PrintName = props => {
  const {
    actor,
    name,
    x,
    y,
    height,
    width,
    classes,
    handleChange,
    value,
    setFieldRef,
    _derived: { isRequired },
  } = props;

  const fieldStyle = {
    top: y,
    left: x,
    height,
    width,
    fontSize: height * 0.8,
  };

  const isValuePresent = !!value;
  const mainInput = value ? classes.signatureInput : classes.input;
  const inputClass = classNames(mainInput, fieldStatusClass(props));

  const handlePrintNameButtonClick = () => {
    const name = actor.name;
    handleChange(name);
  };

  return (
    <div className={classes.root} style={fieldStyle}>
      <MuiTextField
        id={name}
        inputRef={e => setFieldRef(e, y)}
        placeholder={isRequired ? 'Required' : ''}
        className={classes.textField}
        value={isValuePresent ? value : ''}
        multiline={false}
        type="text"
        onChange={handlePrintNameButtonClick}
        margin="none"
        disabled={true}
        InputProps={{
          disableUnderline: false,
          classes: { input: inputClass },
          style: { fontSize: height * 0.8 },
        }}
      />
      {!isValuePresent && (
        <IconButton
          className={classes.printNameButton}
          onClick={() => handlePrintNameButtonClick()}
        >
          <PrintNameIcon />
        </IconButton>
      )}
    </div>
  );
};

export default withStyles(styles)(PrintName);
