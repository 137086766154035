import React from 'react';

import { withStyles } from '@mui/styles';
import MuiTextField from '@mui/material/TextField';
import classNames from 'classnames';
import sharedCss from '../../shared/oldDocumentSignerUI/sharedCss';
import fieldStatusClass from './fieldStatusClass';
import truncate from '../../utilities/truncate';

const styles = theme => ({
  textField: {
    position: 'absolute !important',
  },
  input: {
    paddingLeft: '5px !important',
    paddingRight: '5px !important',
    backgroundColor: '#EEFF0C4D',
  },
  numberInput: {
    paddingLeft: '5px !important',
    paddingRight: '5px !important',
    backgroundColor: '#53FF0C4D',
  },
  ...sharedCss.fields,
});

const NumberField = props => {
  const {
    id,
    name,
    x,
    y,
    height,
    width,
    handleChange,
    classes,
    value = '',
    setFieldRef,
    max,
    _derived: { isRequired, isDisabled },
  } = props;

  const fieldDynamicStyle = {
    top: y,
    left: x,
    height,
    width,
  };
  const mainInput = value ? classes.numberInput : classes.input;
  const inputClass = classNames(mainInput, fieldStatusClass(props));

  const onChange = ({ target: { value } }) => {
    value = `${value}`.replace(/[^0-9.-]+/g, '');

    // Ensure the minus sign is only at the beginning
    let minusCount = (value.match(/-/g) || []).length;
    if (minusCount > 1) {
      value = value.replace(/-/g, '');
      value = '-' + value;
    }

    // Ensure there is only one decimal point
    let parts = value.split('.');
    if (parts.length > 2) {
      value = parts[0] + '.' + parts.slice(1).join('');
    }

    value = truncate(value, max);
    handleChange(value);
  };

  return (
    <MuiTextField
      variant="standard"
      id={name}
      inputRef={e => setFieldRef(e, y)}
      inputMode="numeric"
      pattern="[0-9]*"
      className={classes.textField}
      value={value === null || value === undefined ? '' : value}
      style={fieldDynamicStyle}
      type="text"
      onChange={onChange}
      margin="none"
      disabled={isDisabled}
      data-test-id={`NumberField-root-${id}`}
      placeholder={isRequired ? 'Required' : ''}
      InputProps={{
        disableUnderline: isDisabled,
        classes: {
          input: inputClass,
        },
        inputProps: {
          'data-test-id': `NumberField-input-${id}`,
        },
      }}
    />
  );
};

export default withStyles(styles)(NumberField);
