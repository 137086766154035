import { format as formatDate } from 'date-fns';
import truncate from 'lodash.truncate';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';
import useFeatureFlags from 'common/hooks/useFeatureFlags';
import {
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Paper,
  Typography,
  CircularProgress,
} from '@mui/material';
import { Check as CheckMarkIcon, ChevronLeft } from '@mui/icons-material';
import MobileSaveSignatureDialog from './MobileSaveSignatureDialog';
import MobileClickThroughAcknowledgment from './MobileClickThroughAcknowledgment';
import decorateDocuments from 'mobile/components/DocumentSigner/DocumentSigner/decorateDocuments';
import { DocumentPaneContainer } from 'packages/mobile-document-signer';
import mapStartPlusFieldToDsrInput from './mapStartPlusFieldToDsrInput';
import isFilledIn from 'mobile/components/DocumentSigner/utilities/isFilledIn';
import MobileGroupedAcknowledgmentDialog from './MobileGroupedAcknowledgmentDialog';
import GrowlNotification from 'mobile/components/DocumentSigner/DocumentSigner/GrowlNotification';
import MobileSaveDraftDialog from './MobileSaveDraftDialog';

const AUTOSAVE_INTERVAL = 10000;

const styles = makeStyles({
  rootCollapsed: {
    backgroundColor: '#f7fcfc',
    minHeight: '100vh',
  },
  navbarCollapsed: {
    marginTop: '120px',
  },
  rootExpanded: {
    marginBottom: -130, // Stops root from overflowing so no scroll
  },
  navbarExpanded: {
    marginTop: -10,
  },
  crew: {
    color: '#34bfb6',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: 1,
    textTransform: 'uppercase',
    borderRadius: 4,
    backgroundColor: '#9A9FAF',
    padding: '6px',
    textAlign: 'center',
  },
  name: {
    marginLeft: '20px',
    color: '#646464',
    fontSize: '35px',
    fontWeight: 400,
  },
  grayedCheckMark: {
    color: '#cfcfcf',
    width: 30,
    height: 30,
  },
  greenCheckMark: {
    color: '#2ecc71',
    width: 30,
    height: 30,
  },
  formTitle: {
    color: '#646464',
    fontSize: '16px',
    fontWeight: 400,
    marginLeft: '15px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  itemRow: {
    borderBottom: '1px solid #cfcfcf',
    padding: '20px 10px 10px',
  },
  topSection: {
    marginLeft: '20px',
    marginTop: '20px',
    width: '60px',
  },
  expandedIcon: {
    transform: 'rotate(90deg)',
    color: '#ffffff',
    backgroundColor: '#0000FF',
    height: '24px',
    width: '24px',
    borderRadius: 50,
  },
  expandIcon: {
    color: '#0000FF',
    backgroundColor: '#f5f5f5',
    height: '24px',
    width: '24px',
    borderRadius: 50,
    transform: 'rotate(-90deg)',
  },
  icon: {
    position: 'relative',
    bottom: '10px',
    right: '10px',
  },
  bottomSection: {
    width: '100vw',
    boxShadow: '0 -4px 10px #cccccc',
    backgroundColor: '#f7fcfc',
    padding: '10px',
    position: 'fixed',
    left: 0,
    bottom: 0,
  },
  approveBtn: {
    borderRadius: 2,
  },
  disabledApproveBtn: {
    backgroundColor: '#0000FF',
    borderRadius: 2,
    opacity: 0.4,
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
  rejectAll: {
    color: '#0000FF',
    fontSize: '16px !important',
  },
  completedStatus: {
    textAlign: 'right',
    color: '#646464',
    fontSize: 12,
    fontWeight: 400,
    marginBottom: '10px',
    marginRight: '5px',
  },
  navigatingForms: {
    width: '100%',
    opacity: 0.85,
  },
  itemOpen: {
    borderLeft: '4px solid #0000FF',
    borderBottom: '1px solid #cfcfcf',
    height: '60px !important',
    minHeight: 'unset',
  },
  paperItemOpen: {
    backgroundColor: '#ffffff',
    marginBottom: '150px',
    marginTop: '120px',
  },
  paperItemClosed: {
    backgroundColor: '#ffffff',
    marginTop: '30px',
    marginBottom: '150px',
  },
  saveProgress: {
    marginRight: '15px !important',
    '&:focus': {
      background: 'transparent !important',
    },
    '&:hover': {
      background: '#0000FF',
      color: '#FFFFFF',
    },
  },
  nextButton: {
    marginRight: '10px !important',
    background: '#0000FF',
    '&:hover': {
      background: '#1A55FD',
    },
    '&:active': {
      background: '#0000B3',
    },
  },
  panelContainer: {
    height: '30px',
  },
  panelDetails: {
    padding: '10px 4px',
    background: '#eaf1f1',
  },
  btnContainer: {
    flexBasis: 'auto !important',
  },
  error: {
    color: 'red',
    margin: '20px',
    textAlign: 'center',
  },
  docPane: {
    height: '100%',
    width: '100%',
  },
  docPaneContainer: {
    overflowX: 'scroll',
    overflowY: 'scroll',
    height: 'calc(100vh - 195px)',
    '& *': {
      fontSize: '20px !important',
    },
  },
  busyOverlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: 'rgba(195, 195, 195, 0.8)',
    zIndex: 100000,
    display: 'grid',
    placeContent: 'center',
  },
  busyContainer: {
    textAlign: 'center',
  },
  userGuide: {
    paddingLeft: 18,
    paddingTop: 30,
    fontSize: '18px !important',
  },
  userSubGuide: {
    paddingLeft: 30,
    fontSize: '14px !important',
  },
  offersBtnContainer: {
    paddingLeft: 18,
    paddingTop: 22,
  },
  footerContainer: {
    borderTop: '1px solid rgba(209, 209, 209, 1)',
    padding: '13px 0px',
    marginTop: 10,
    marginBottom: -12,
    marginLeft: -12,
    marginRight: -12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
    fontSize: '1.2rem',
    paddingTop: 22,
    paddingLeft: 18,
  },
  offersBtn: {
    backgroundColor: '#0000FF',
    textDecoration: 'none',
    color: '#FFFFFF',
    padding: '6px 8px',
  },
});

// Returns true if signature is saved.
// Returns false if signature is not saved.
// Returns null if user must be asked whether or not to save signature.
const getIsSignatureSavedFromLocalStorage = token => {
  // If no token then API call has not yet resolved so don't ask user yet.
  if (!token) return false;

  // Get the isSignature saved token/value pair. If not present, ask user.
  const savedTokenAndValue = window.sessionStorage.getItem('isSignatureSaved');
  if (!savedTokenAndValue) return null;

  // Check the token is for the right session. If it is, return saved value.
  const [savedToken, savedValue] = savedTokenAndValue.split('-');
  if (savedToken === token) return savedValue === 'true';

  // Token is for wrong session, return null.
  return null;
};

const saveIsSignatureSavedToLocalStorage = (token, value) => {
  const tokenAndValue = `${token}-${!!value}`;
  window.sessionStorage.setItem('isSignatureSaved', tokenAndValue);
};

const autosave = debounce((save, documents) => {
  save(documents, true);
}, AUTOSAVE_INTERVAL);

const MobileDocumentSigner = props => {
  const {
    actions,
    actor,
    documents: upstreamDocuments = [],
    getDocumentImageUrls,
    me,
    templatesAcknowledgeNotes,
    templatesAcknowledgeTitle,
    throwFatalError,
  } = props;
  const {
    save: { action: save },
    submit: { action: submit },
    cancel: { action: reject, text: rejectText = '' },
  } = actions;
  const { data: { signature_session: token } = {} } = me;

  const [currentDocumentId, setCurrentDocumentId] = useState(null);
  const [editedDocuments, setEditedDocuments] = useState(null);
  const [isSignatureSaved, setIsSignatureSaved] = useState(false);
  const [selectedInputId, setSelectedInputId] = useState('');
  const [saveDraft, setSaveDraft] = useState(false);
  const [saveInProgress, setSaveInProgress] = useState(false);

  const [zoom, setZoom] = useState(1);
  const [
    isGroupedAcknowledgmentOpen,
    setIsGroupedAcknowledgmentOpen,
  ] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);
  const [notificationState, setNotificationState] = useState({
    growlType: null,
    growlOpen: false,
  });

  // An object where keys are documentIds and values are arrays of page image URLs
  const [pageImages, setPageImages] = useState(
    Object.fromEntries(
      upstreamDocuments.map(({ id, pages }) => [id, Array(pages).fill(null)]),
    ),
  );

  const classes = styles();

  const getIsDocumentComplete = doc => {
    const { _derived: { isComplete = false } = {} } = doc || {};
    return isComplete;
  };

  const documents =
    editedDocuments ||
    decorateDocuments(upstreamDocuments, serverErrors, false);
  const currentDocument =
    currentDocumentId && documents.find(({ id }) => id === currentDocumentId);
  const formsCompletedCount = documents.filter(getIsDocumentComplete).length;
  const isOfferComplete = documents.every(getIsDocumentComplete);
  const documentCount = documents.length;
  const expansionPanelDocs = currentDocumentId ? [currentDocument] : documents;
  const {
    acknowledge_title: acknowledgeTitle,
    acknowledge_notes: acknowledgeNotes,
    acknowledge_type: acknowledgeType,
    crew_acknowledged: crewAcknowledged,
  } = currentDocument ?? {};
  const showCta = acknowledgeType === 'C' && !crewAcknowledged;
  const groupedAcknowledgmentDocumentNames = documents
    .filter(doc => doc.acknowledge_type === 'G' && doc.status !== 'complete')
    .map(doc => doc.templateName);
  const doesOfferRequireValidatedSignature = documents.some(({ fields }) =>
    fields.some(({ _derived: { isValidated } }) => isValidated),
  );

  const { growlType, growlOpen } = notificationState;
  // TODO Improve performance
  const documentSignerInputs =
    currentDocument &&
    currentDocument.fields
      .flatMap(field => mapStartPlusFieldToDsrInput(field, currentDocument))
      .filter(x => x);

  const documentSignerPages =
    currentDocument &&
    pageImages[currentDocumentId].map((imageUrl, pageIndex) => {
      const inputs = documentSignerInputs.filter(
        input => input.page === pageIndex + 1,
      );
      return { imageUrl, inputs };
    });

  const goToNext = () => {
    // Is document complete?
    setNotificationState({ ...notificationState, growlOpen: false });
    const isCurrentDocumentComplete = getIsDocumentComplete(currentDocument);
    if (isCurrentDocumentComplete) {
      // Find the first incomplete document, if any
      const firstIncompleteDocument = documents.find(
        doc => !getIsDocumentComplete(doc),
      );
      // If an incomplete document is found open it, otherwise just close all
      setCurrentDocumentId(
        firstIncompleteDocument && firstIncompleteDocument.id,
      );
      return;
    }

    // Otherwise select first incomplete field in current document
    const firstIncompleteRequiredField = currentDocument.fields.find(
      ({ _derived: { error, isRequired }, value }) =>
        error || (isRequired && !isFilledIn(value)),
    );
    if (!firstIncompleteRequiredField) return; // This shoudn't happen
    setSelectedInputId(firstIncompleteRequiredField.id);
  };

  useEffect(() => {
    setIsSignatureSaved(getIsSignatureSavedFromLocalStorage(token));
  }, [token]);

  useEffect(() => {
    setSelectedInputId('');
    setZoom(1);
  }, [currentDocumentId]);

  const saveIsSignatureSaved = value => {
    setIsSignatureSaved(value);
    saveIsSignatureSavedToLocalStorage(token, value);
  };

  const handleNeedPage = async pageIndex => {
    const urls = await getDocumentImageUrls({
      documentId: currentDocumentId,
      page: pageIndex + 1,
    });
    const newUrl = urls[0];
    setPageImages(pageImages => {
      const newDocPageImages = pageImages[currentDocumentId].map((url, i) =>
        i === pageIndex ? newUrl : url,
      );
      return {
        ...pageImages,
        [currentDocumentId]: newDocPageImages,
      };
    });
  };

  const zoomAndCenterInput = inputId => {
    setZoom(2.5);
    setTimeout(() => setSelectedInputId(inputId), 100);
  };

  const notifyIfDocumentComplete = doc => {
    const {
      fields,
      _derived: { isComplete, error: docError },
    } = doc;
    const docFieldError = fields.some(f => f._derived.error);
    const canContinue = isComplete && !docError && !docFieldError;
    if (canContinue) {
      setNotificationState({ growlType: 'documentComplete', growlOpen: true });
    }
  };

  const setInputValue = (inputId, value) => {
    if (value instanceof Date) {
      const dateFormat =
        documentSignerInputs.find(({ id }) => id === inputId)?.dateFormat ||
        'MM/dd/yyyy';
      value = formatDate(value, dateFormat);
    }
    const indexOfSeperator = inputId.indexOf('/');
    // if inputId contains more than 1 "/", then the radioName is updating wrong value
    const fieldId =
      indexOfSeperator >= 0 ? inputId.slice(0, indexOfSeperator) : inputId;
    const radioName =
      indexOfSeperator >= 0 ? inputId.slice(indexOfSeperator + 1) : undefined;
    const currentDocument = documents.find(
      ({ id }) => id === currentDocumentId,
    );
    notifyIfDocumentComplete(currentDocument);
    setEditedDocuments(editedDocuments => {
      const docs = editedDocuments || documents;
      const updatedDocs = docs.map(doc =>
        doc.id === currentDocumentId
          ? {
              ...doc,
              fields: doc.fields.map(field =>
                field.id === fieldId
                  ? { ...field, value: radioName ?? value }
                  : field,
              ),
            }
          : doc,
      );
      const decoratedDocuments = decorateDocuments(
        updatedDocs,
        serverErrors,
        fieldId,
      );
      autosave(save, decoratedDocuments);
      return decoratedDocuments;
    });
  };

  // For some reason the preloaded images are expiring, we're not sure why.
  // So if DSR complains of a broken link, reload images from source.
  const handlePageLoadError = (pageIndex, err, tryCount) => {
    // After retrying 3 times, give up loading the page image
    if (tryCount >= 3) {
      throwFatalError('Unable to load Document Images', err);
      return;
    }

    // Try reloading the image from source
    handleNeedPage(pageIndex);
  };

  const acknowledge = () => {
    setEditedDocuments(editedDocuments => {
      const docs = editedDocuments || documents;
      const updatedDocs = docs.map(doc =>
        doc.id === currentDocumentId
          ? { ...doc, crew_acknowledged: true }
          : doc,
      );
      const decoratedDocuments = decorateDocuments(updatedDocs, serverErrors);
      autosave(save, decoratedDocuments);
      return decoratedDocuments;
    });
  };

  const handleSaveDraftClose = () => {
    setSaveDraft(false);
  };

  const catchErrors = (promise, type) => {
    setSaveInProgress(true);
    promise
      .then(res => {
        if (res?.payload?.data?.items && type === 'save') {
          setSaveInProgress(false);
          setSaveDraft(true);
        }
        setServerErrors([]);
      })
      .catch(errors => setServerErrors(errors || []));
  };

  const hideNotification = (event, reason) => {
    if (reason === 'clickaway') return;
    setNotificationState({ ...notificationState, growlOpen: false });
  };

  const cleanUpNotification = () => {
    setNotificationState({ ...notificationState, growlType: null });
  };
  const flags = useFeatureFlags();
  const globalNavFlag = flags.includes('GlobalNavbar');
  const collapsedClass = classnames(classes.rootCollapsed, {
    [classes.navbarCollapsed]: globalNavFlag,
  });
  const expandedClass = globalNavFlag
    ? classes.navbarExpanded
    : classes.rootExpanded;

  return (
    <React.Fragment>
      <div className={currentDocumentId ? expandedClass : collapsedClass}>
        {!isOfferComplete && currentDocumentId === null && (
          <div data-test-id="MobileDocumentSigner-getStarted">
            <Typography variant="h4" gutterBottom className={classes.userGuide}>
              Here are your Offer Forms
            </Typography>
            <Typography variant="h5" className={classes.userSubGuide}>
              Let's get started
            </Typography>
          </div>
        )}
        {isOfferComplete && (
          <Typography
            variant="h4"
            gutterBottom
            data-test-id="MobileDocumentSigner-greatJob"
            className={classes.userGuide}
          >
            Great job! Submit for Approval
          </Typography>
        )}
        <Paper
          className={
            currentDocumentId ? classes.paperItemOpen : classes.paperItemClosed
          }
        >
          {/*isLoading && <PreLoader />*/}
          {expansionPanelDocs.length > 0 &&
            expansionPanelDocs.map(doc => (
              <Accordion
                key={doc.id}
                expanded={currentDocumentId === doc.id}
                onChange={(event, expanded) => {
                  setCurrentDocumentId(expanded ? doc.id : false);
                }}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  className={currentDocumentId ? classes.itemOpen : ''}
                >
                  <Grid container className={classes.panelContainer}>
                    <Grid item xs={1}>
                      <IconButton
                        className={classes.icon}
                        data-test-id={`MobileDocumentSigner-expander-${doc.id}`}
                      >
                        <ChevronLeft
                          className={
                            currentDocumentId
                              ? classes.expandedIcon
                              : classes.expandIcon
                          }
                        />
                      </IconButton>
                    </Grid>
                    <Grid item xs={10}>
                      <Typography variant="h6" className={classes.formTitle}>
                        {truncate(doc.name || doc.templateName, {
                          length: 46,
                        })}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <CheckMarkIcon
                        fontSize="large"
                        className={
                          getIsDocumentComplete(doc)
                            ? classes.greenCheckMark
                            : classes.grayedCheckMark
                        }
                      />
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails className={classes.panelDetails}>
                  {currentDocumentId && (
                    <div className={classes.docPaneContainer}>
                      <DocumentPaneContainer
                        actor={actor}
                        className={classes.docPane}
                        isAutosignActive={!!isSignatureSaved}
                        onChangeInput={setInputValue}
                        onDoubleClick={() => setZoom(1)}
                        onFocusInput={zoomAndCenterInput}
                        onNeedPage={handleNeedPage}
                        onPageLoadError={handlePageLoadError}
                        pageHeight={currentDocument.images[0].height}
                        pages={documentSignerPages}
                        pageWidth={currentDocument.images[0].width}
                        scrollContainer={document.body}
                        selectedInputId={selectedInputId}
                        zoom={zoom}
                      />
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
        </Paper>
        {serverErrors.length > 0 && (
          <Typography className={classes.error}>
            Sorry an error occurred, please try again
          </Typography>
        )}
        <div className={classes.bottomSection}>
          {!currentDocumentId && (
            <Typography className={classes.completedStatus}>
              {formsCompletedCount} of {documentCount} Forms Completed
            </Typography>
          )}
          <GrowlNotification
            isOpen={growlOpen}
            onClose={hideNotification}
            onExited={cleanUpNotification}
            onContinue={() => goToNext()}
            type={growlType}
          />
          <Grid
            container
            justifyContent={'space-between'}
            alignItems={'center'}
          >
            <Grid item xs={3}>
              <Typography
                className={classes.rejectAll}
                onClick={reject}
                data-test-id="MobileDocumentSigner-reject"
              >
                {rejectText}
              </Typography>
            </Grid>
            <Grid item xs={9} className={classes.btnContainer}>
              {!currentDocumentId && (
                <Button
                  disabled={!isOfferComplete}
                  onClick={() => {
                    if (groupedAcknowledgmentDocumentNames.length > 0) {
                      setIsGroupedAcknowledgmentOpen(true);
                      return;
                    }
                    catchErrors(submit(documents));
                  }}
                  data-test-id="MobileDocumentSigner-approve"
                  className={
                    isOfferComplete
                      ? classes.approveBtn
                      : classes.disabledApproveBtn
                  }
                  color="primary"
                  variant="contained"
                >
                  Submit for Approval
                </Button>
              )}
              {currentDocumentId && (
                <div>
                  <Button
                    className={classes.saveProgress}
                    onClick={() => catchErrors(save(documents), 'save')}
                    data-test-id="MobileDocumentSigner-save"
                    color="primary"
                    variant="outlined"
                  >
                    Save Progress
                  </Button>
                  <Button
                    className={classes.nextButton}
                    onClick={goToNext}
                    data-test-id="MobileDocumentSigner-next"
                    color="primary"
                    variant="contained"
                  >
                    Next Field
                  </Button>
                </div>
              )}
            </Grid>
          </Grid>
          {globalNavFlag && (
            <div className={classes.footerContainer}>
              <a
                href="https://www.castandcrew.com/privacy-policy/"
                target="_blank"
                rel="noopener noreferrer"
              >
                CA Notice at Collection
              </a>
            </div>
          )}
        </div>
      </div>
      <MobileSaveSignatureDialog
        actor={actor}
        onSubmit={saveIsSignatureSaved}
        open={doesOfferRequireValidatedSignature && isSignatureSaved === null}
      />
      <MobileClickThroughAcknowledgment
        isOpen={showCta}
        title={acknowledgeTitle}
        description={acknowledgeNotes}
        onAcknowledge={acknowledge}
      />
      <MobileGroupedAcknowledgmentDialog
        documentNames={groupedAcknowledgmentDocumentNames}
        notes={templatesAcknowledgeNotes}
        onClose={() => setIsGroupedAcknowledgmentOpen(false)}
        onConfirm={() => catchErrors(submit(documents))}
        open={isGroupedAcknowledgmentOpen}
        title={templatesAcknowledgeTitle}
      />
      <MobileSaveDraftDialog open={saveDraft} close={handleSaveDraftClose} />
      {saveInProgress && (
        <div
          className={classes.busyOverlay}
          data-test-id="DocumentSigner-busyOverlay"
        >
          <div className={classes.busyContainer}>
            <CircularProgress />
            <Typography>Processing...</Typography>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default MobileDocumentSigner;
