export const PageInputKind = {
  Checkbox: 'checkbox',
  Date: 'date',
  Number: 'number',
  Radio: 'radio',
  Select: 'select',
  Signature: 'signature',
  Text: 'text',
  Timestamp: 'timestamp',
  PrintName: 'printName',
};

export const PageInputStatus = {
  Disabled: 'disabled',
  Error: 'error',
  Optional: 'optional',
  Required: 'required',
  Success: 'success',
};
