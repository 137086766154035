import React from 'react';

import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import MuiCheckbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import sharedCss from '../../shared/oldDocumentSignerUI/sharedCss';
import fieldStatusClass from './fieldStatusClass';

const styles = {
  ...sharedCss.fields,
  checkBox: {
    position: 'absolute !important',
  },
};

const Checkbox = props => {
  const {
    id,
    name,
    x,
    y,
    height,
    width,
    handleChange,
    classes,
    value,
    setFieldRef,
    _derived: { isDisabled },
  } = props;

  const fieldDynamicStyle = {
    top: y,
    left: x,
    height,
    width,
  };

  const checked = value === true || `${value}`.toLowerCase() === 'true';

  return (
    <MuiCheckbox
      id={name}
      inputRef={e => setFieldRef(e, y)}
      icon={
        <CheckBoxOutlineBlankIcon
          style={{ height: height * 1.55, width: height * 1.55 }}
        />
      }
      checkedIcon={
        <CheckBoxIcon style={{ height: height * 1.55, width: height * 1.55 }} />
      }
      className={classNames(classes.checkBox, fieldStatusClass(props))}
      color="primary"
      checked={checked}
      style={fieldDynamicStyle}
      onChange={e => handleChange(e.target.checked)}
      data-test-id={`Checkbox-root-${id}`}
      disabled={isDisabled}
      inputProps={{
        'data-test-id': `Checkbox-input-${id}`,
        'data-checked': String(value),
      }}
    />
  );
};

export default withStyles(styles)(Checkbox);
