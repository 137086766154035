import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Dialog, Typography, TextField, Button } from '@mui/material';
import { Close } from '@mui/icons-material';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#f7fcfc',
    padding: '20px',
    height: '100vh',
  },
  title: {
    color: '#646464',
    fontSize: '24px !important',
    margin: '10px 20px 0 20px !important',
  },
  formFields: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '60px',
  },
  textField: {
    marginLeft: theme.spacing(1) + ' !important',
    marginRight: theme.spacing(1) + ' !important',
    width: '90%',
    background: '#F7FCFC',
  },
  input: {
    background: '#f7fcfc',
    '& input': {
      fontSize: 16,
    },
  },
  label: {
    color: '#0000FF !important',
  },
  closeBtn: {
    width: '30px !important',
    height: '30px !important',
    color: '#646464',
    marginLeft: '88vw',
  },
  btnContainer: {
    display: 'flex',
    marginTop: '30px',
    marginRight: '30px',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  cancelBtn: {
    marginRight: '15px !important',
  },
  legalCopy: {
    color: '#646464',
    fontSize: '14px !important',
    lineHeight: '21px !important',
    margin: '10px 20px 0 20px !important',
  },
  viewMore: {
    color: '#0000FF',
    fontSize: '14px !important',
    marginLeft: '20px !important',
    marginTop: '10px !important',
  },
  error: {
    color: 'red',
  },
  agreeBtn: {
    '&:focus': {
      backgroundColor: '#0000FF !important',
      color: '#FFF',
    },
    '&:hover': {
      backgroundColor: '#0000FF !important',
      color: '#FFF',
    },
  },
}));

const DocumentPageSignatureInputDialog = ({
  isInitials,
  onClose,
  onSubmit,
  open,
  requiredImpression,
}) => {
  const classes = useStyles();
  const [isViewMoreOpen, setIsViewMoreOpen] = useState(false);
  const [impression, setImpression] = useState('');
  const [wasSubmitted, setWasSubmitted] = useState(false);

  useEffect(() => {
    if (open) {
      setIsViewMoreOpen(false);
      setImpression('');
      setWasSubmitted(false);
    }
  }, [open]);

  const getImpressionError = impression => {
    if (!impression) return 'Cannot be blank';

    const isImpressionInvalid =
      requiredImpression &&
      impression.toLowerCase() !== requiredImpression.trim().toLowerCase();

    if (isImpressionInvalid) {
      return isInitials
        ? `Please enter your initials as recorded in your profile: ${requiredImpression}`
        : `Please enter your full legal name as recorded in your profile: ${requiredImpression}`;
    }

    return '';
  };

  const impressionError = wasSubmitted ? getImpressionError(impression) : '';

  const handleSubmit = () => {
    setWasSubmitted(true);
    if (getImpressionError(impression)) return;
    onSubmit(impression);
    onClose();
  };

  return (
    <Dialog
      fullScreen
      onClose={onClose}
      open={open}
      data-tid="DocumentPageSignatureInputDialog-root"
    >
      <div className={classes.root}>
        <Close onClick={onClose} className={classes.closeBtn} />
        <Typography className={classes.title}>
          {isInitials ? 'Initials' : 'Signature'}
        </Typography>
        <Typography className={classes.legalCopy}>
          Only the person associated with these records is permitted to use this
          system through this account. Completion and submission of the online
          forms available through this system will create legally binding
          obligations associated with each form. Please read each form
          carefully. Execution of each form will be accomplished through
          clicking on the “I{' '}
          {isViewMoreOpen && (
            <span>
              Agree” button provided at the bottom of each form. By clicking “I
              Agree” at the bottom of each form, you will be acknowledging that
              you have read the applicable form, understand the requirements for
              submission of the information, agree to submit complete and
              accurate information, and further agree to submit and sign these
              forms electronically through this system.
            </span>
          )}
        </Typography>
        <Typography
          className={classes.viewMore}
          onClick={() => setIsViewMoreOpen(isViewMoreOpen => !isViewMoreOpen)}
        >
          {isViewMoreOpen ? 'Show less' : 'View More'}
        </Typography>
        <div className={classes.formFields}>
          <TextField
            className={classes.textField}
            data-tid="DocumentPageSignatureInputDialog-input"
            label={isInitials ? 'Initials' : 'Full Legal Name'}
            margin="normal"
            variant="filled"
            InputLabelProps={{
              className: classes.label,
            }}
            InputProps={{
              className: classes.input,
            }}
            onChange={e => setImpression(e.target.value.trim())}
          />
          {impressionError && (
            <Typography
              className={classes.error}
              data-tid="DocumentPageSignatureInputDialog-error"
            >
              {impressionError}
            </Typography>
          )}
        </div>
        <div className={classes.btnContainer}>
          <Button
            onClick={onClose}
            className={classes.cancelBtn}
            data-tid="DocumentPageSignatureInputDialog-cancel"
            color="primary"
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            onClick={handleSubmit}
            className={classes.agreeBtn}
            color="primary"
            variant="contained"
            data-tid="DocumentPageSignatureInputDialog-submit"
          >
            I Agree
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DocumentPageSignatureInputDialog;
