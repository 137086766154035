import React, { useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { useInView } from 'react-intersection-observer';
import DocumentPageImage from './DocumentPageImage';
import DocumentPageInputs from './DocumentPageInputs';

const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: '100%',
  },
});

const DocumentPage = ({
  actor,
  dataTid,
  isAutosignActive,
  onBecomeCurrent,
  onChangeInput,
  onFocusInput,
  onImageLoadError,
  onReachInputMaxLength,
  page,
  pageHeight,
  pageWidth,
  scrollContainer,
  handleChange,
}) => {
  const { imageUrl, inputs } = page;
  const classes = useStyles();
  const { ref, inView } = useInView({
    root: scrollContainer,
    rootMargin: '0px 0px -50% 0px',
  });

  useEffect(() => {
    if (inView) onBecomeCurrent();
  }, [inView]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classes.root} data-tid={dataTid} ref={ref}>
      <DocumentPageImage
        imageUrl={imageUrl}
        pageHeight={pageHeight}
        pageWidth={pageWidth}
        onImageLoadError={onImageLoadError}
      />
      {inputs && (
        <DocumentPageInputs
          actor={actor}
          onChangeInput={onChangeInput}
          onFocusInput={onFocusInput}
          onReachInputMaxLength={onReachInputMaxLength}
          inputs={inputs}
          isAutosignActive={isAutosignActive}
          pageHeight={pageHeight}
          pageWidth={pageWidth}
          handleChange={handleChange}
        />
      )}
    </div>
  );
};

export default DocumentPage;
