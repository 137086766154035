import React from 'react';
import DocumentPageCheckboxInput from './DocumentPageCheckboxInput';
import DocumentPageDateInput from './DocumentPageDateInput';
import DocumentPageNumberInput from './DocumentPageNumberInput';
import DocumentPageRadioInput from './DocumentPageRadioInput';
import DocumentPageSelectInput from './DocumentPageSelectInput';
import DocumentPageSignatureInput from './DocumentPageSignatureInput';
import DocumentPageTextInput from './DocumentPageTextInput';
import DocumentPageTimestampInput from './DocumentPageTimestampInput';
import DocumentPagePrintNameInput from './DocumentPagePrintNameInput';
import { PageInputKind } from '../../types';

const DocumentPageInput = ({
  actor,
  isAutosignActive,
  onChange,
  onFocus,
  onReachMaxLength,
  input,
}) => {
  switch (input.kind) {
    case PageInputKind.Checkbox:
      return (
        <DocumentPageCheckboxInput
          input={input}
          onChange={onChange}
          onFocus={onFocus}
        />
      );
    case PageInputKind.Date:
      return (
        <DocumentPageDateInput
          input={input}
          onChange={onChange}
          onFocus={onFocus}
        />
      );
    case PageInputKind.Number:
      return (
        <DocumentPageNumberInput
          input={input}
          onChange={onChange}
          onFocus={onFocus}
          onReachMaxLength={onReachMaxLength}
        />
      );
    case PageInputKind.Radio:
      return (
        <DocumentPageRadioInput
          input={input}
          onChange={onChange}
          onFocus={onFocus}
        />
      );
    case PageInputKind.Select:
      return (
        <DocumentPageSelectInput
          input={input}
          onChange={onChange}
          onFocus={onFocus}
        />
      );
    case PageInputKind.Signature:
      return (
        <DocumentPageSignatureInput
          actor={actor}
          input={input}
          isAutosignActive={isAutosignActive}
          onChange={onChange}
          onFocus={onFocus}
        />
      );
    case PageInputKind.Text:
      return (
        <DocumentPageTextInput
          input={input}
          onChange={onChange}
          onFocus={onFocus}
        />
      );
    case PageInputKind.Timestamp:
      return (
        <DocumentPageTimestampInput
          input={input}
          onChange={onChange}
          onFocus={onFocus}
        />
      );
    case PageInputKind.PrintName:
      return (
        <DocumentPagePrintNameInput
          actor={actor}
          input={input}
          onChange={onChange}
          onFocus={onFocus}
        />
      );
    default:
      return null;
  }
};

export default DocumentPageInput;
