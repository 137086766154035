import React, { useState, Fragment } from 'react';
import { makeStyles } from '@mui/styles';
import GestureIcon from '@mui/icons-material/Gesture';
import useStatusClass from './useStatusClass';
import DocumentPageSignatureInputDialog from './DocumentPageSignatureInputDialog';
import { PageInputStatus } from '../../types';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    border: 0,
    padding: 0,
  },
  icon: {
    fontSize: '14px',
    float: 'right',
    marginRight: 5,
  },
  readout: {
    color: 'black',
    float: 'left',
    marginLeft: '5px',
  },
  impression: {
    color: '#333',
    fontFamily: 'Caflischscriptpro',
  },
  '@font-face': {
    fontFamily: 'Caflischscriptpro',
    src: [
      "url('https://payrollplus-assets.castandcrew.com/caflischscriptpro-regular.otf')",
    ],
  },
});

const DocumentPageSignatureInput = ({
  actor,
  input,
  isAutosignActive,
  onChange,
  onFocus,
}) => {
  const { id, isInitials, isValidated, status, value } = input;
  const { name, initials } = actor;
  const expectedImpression = isInitials ? initials : name;

  const classes = useStyles();
  const statusClass = useStatusClass(status);

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClick = () => {
    if (isValidated && isAutosignActive) {
      onChange(expectedImpression);
      return;
    }
    setIsDialogOpen(true);
  };

  return (
    <Fragment>
      <button
        className={`${statusClass} ${classes.root}`}
        data-tid={`DocumentPageSignatureInput-button-${id}`}
        disabled={status === PageInputStatus.Disabled}
        onClick={handleClick}
        onFocus={() => onFocus()}
      >
        <span
          className={`${classes.readout} ${
            value !== null && value !== undefined ? value : classes.impression
          }`}
        >
          {value ? value : `- click to ${isInitials ? 'initial' : 'sign'} -`}
        </span>
        <GestureIcon className={classes.icon} />
      </button>
      <DocumentPageSignatureInputDialog
        isInitials={isInitials}
        onClose={() => setIsDialogOpen(false)}
        onSubmit={onChange}
        open={isDialogOpen}
        requiredImpression={isValidated ? expectedImpression : ''}
      />
    </Fragment>
  );
};

export default DocumentPageSignatureInput;
