import React from 'react';

import classNames from 'classnames';
import { withStyles } from '@mui/styles';
import { IconButton, Tooltip, Typography } from '@mui/material';
import {
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  LastPage as LastPageIcon,
  FirstPage as FirstPageIcon,
} from '@mui/icons-material';

const styles = theme => ({
  root: {
    display: 'grid',
    width: '100%',
    placeItems: 'center',
    gridTemplateRows: '100%',
  },
  fiveColRoot: {
    gridTemplateColumns: 'repeat(2, 30px) 0.8fr repeat(2, 30px)',
  },
  sevenColRoot: {
    gridTemplateColumns: 'repeat(3, 30px) 0.8fr repeat(3, 30px)',
  },
  text: {
    color: 'white',
    fontSize: '15.6px !important',
    whiteSpace: 'nowrap',
  },
  enabled: {
    fontSize: 30,
    color: 'white',
  },
  disabled: {
    fontSize: 30,
    color: 'grey',
  },
  doubleArrowLeft: {
    position: 'absolute',
    right: 18,
  },
  doubleArrowRight: {
    position: 'absolute',
    left: 18,
  },
  withSidebar: {
    color: 'black',
  },
  withSidebarText: {
    color: '#7b7b7b',
  },
  tooltip: {
    fontSize: '0.9rem !important',
  },
});

const PageIncrement = props => {
  const {
    classes,
    page,
    pageCount,
    setCurrentDocument,
    setPage,
    prevDocId,
    nextDocId,
    withSidebar,
  } = props;
  const rootClass = classNames(
    classes.root,
    setCurrentDocument ? classes.sevenColRoot : classes.fiveColRoot,
  );

  return (
    <div className={rootClass}>
      {setCurrentDocument && (
        <Tooltip
          disableInteractive
          title="Previous Document"
          enterDelay={500}
          classes={{ tooltip: classes.tooltip }}
        >
          <div>
            <IconButton
              disabled={!prevDocId}
              onClick={() => setCurrentDocument(prevDocId)}
              data-test-id="PageIncrement-prevDocButton"
            >
              <KeyboardArrowLeftIcon
                className={classNames(
                  classes[prevDocId ? 'enabled' : 'disabled'],
                  {
                    [classes.withSidebar]: withSidebar && prevDocId,
                  },
                )}
              />
              <KeyboardArrowLeftIcon
                className={classNames(
                  classes.doubleArrowLeft,
                  classes[prevDocId ? 'enabled' : 'disabled'],
                  {
                    [classes.withSidebar]: withSidebar && prevDocId,
                  },
                )}
              />
            </IconButton>
          </div>
        </Tooltip>
      )}
      <Tooltip
        disableInteractive
        title="Start of Document"
        enterDelay={500}
        classes={{ tooltip: classes.tooltip }}
      >
        <div>
          <IconButton
            disabled={page <= 1}
            onClick={() => setPage(null, 1)}
            data-test-id="PageIncrement-firstPageButton"
          >
            <FirstPageIcon
              className={classNames(
                classes[page > 1 ? 'enabled' : 'disabled'],
                {
                  [classes.withSidebar]: withSidebar && page > 1,
                },
              )}
            />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip
        disableInteractive
        title="Previous Page"
        enterDelay={500}
        classes={{ tooltip: classes.tooltip }}
      >
        <div>
          <IconButton
            disabled={page <= 1}
            onClick={() => setPage(-1)}
            data-test-id="PageIncrement-prevPageButton"
          >
            <KeyboardArrowLeftIcon
              className={classNames(
                classes[page > 1 ? 'enabled' : 'disabled'],
                {
                  [classes.withSidebar]: withSidebar && page > 1,
                },
              )}
            />
          </IconButton>
        </div>
      </Tooltip>
      <Typography
        className={classNames(classes.text, {
          [classes.withSidebarText]: withSidebar,
        })}
        data-test-id="PageIncrement-positionInfo"
      >
        Page {page} of {pageCount}
      </Typography>
      <Tooltip
        disableInteractive
        title="Next Page"
        enterDelay={500}
        classes={{ tooltip: classes.tooltip }}
      >
        <div>
          <IconButton
            disabled={page >= pageCount}
            onClick={() => setPage(1)}
            data-test-id="PageIncrement-nextPageButton"
          >
            <KeyboardArrowRightIcon
              className={classNames(
                classes[page < pageCount ? 'enabled' : 'disabled'],
                {
                  [classes.withSidebar]: withSidebar && page < pageCount,
                },
              )}
            />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip
        disableInteractive
        title="End of Document"
        enterDelay={500}
        classes={{ tooltip: classes.tooltip }}
      >
        <div>
          <IconButton
            disabled={page >= pageCount}
            onClick={() => setPage(null, pageCount)}
            data-test-id="PageIncrement-lastPageButton"
          >
            <LastPageIcon
              className={classNames(
                classes[page < pageCount ? 'enabled' : 'disabled'],
                {
                  [classes.withSidebar]: withSidebar && page < pageCount,
                },
              )}
            />
          </IconButton>
        </div>
      </Tooltip>
      {setCurrentDocument && (
        <Tooltip
          disableInteractive
          title="Next Document"
          enterDelay={500}
          classes={{ tooltip: classes.tooltip }}
        >
          <div>
            <IconButton
              disabled={!nextDocId}
              onClick={() => setCurrentDocument(nextDocId)}
              data-test-id="PageIncrement-nextDocButton"
            >
              <KeyboardArrowRightIcon
                className={classNames(
                  classes[nextDocId ? 'enabled' : 'disabled'],
                  {
                    [classes.withSidebar]: withSidebar && nextDocId,
                  },
                )}
              />
              <KeyboardArrowRightIcon
                className={classNames(
                  classes.doubleArrowRight,
                  classes[nextDocId ? 'enabled' : 'disabled'],
                  {
                    [classes.withSidebar]: withSidebar && nextDocId,
                  },
                )}
              />
            </IconButton>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default withStyles(styles)(PageIncrement);
