import React from 'react';
import { makeStyles } from '@mui/styles';
import useStatusClass from './useStatusClass';
import { PageInputStatus } from '../../types';

const useStyles = makeStyles({
  root: {
    border: 0,
    color: 'black',
    height: '100%',
    padding: 0,
    paddingLeft: '1%',
    width: '100%',
  },
});

const DocumentPageTextInput = ({ onChange, onFocus, input }) => {
  const { id, status, value } = input;
  const isDisabled = status === PageInputStatus.Disabled;
  const classes = useStyles();
  const statusClass = useStatusClass(status);
  const className = `${statusClass} ${classes.root}`;

  return isDisabled ? (
    <div className={className}>
      {value !== null && value !== undefined ? value : ''}
    </div>
  ) : (
    <input
      type="text"
      className={className}
      data-tid={`DocumentPageTextInput-input-${id}`}
      onChange={e => onChange(e.target.value)}
      onFocus={() => onFocus()}
      value={value !== null && value !== undefined ? value : ''}
    />
  );
};

export default DocumentPageTextInput;
