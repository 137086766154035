import React from 'react';

export default props => (
  <svg
    width={60}
    height={54}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g fill="none" fillRule="evenodd">
      <path d="M-2-4h64v64H-2z" />
      <path
        fill="white"
        fillRule="nonzero"
        d="M48 34.654H37.714V18.5H22.286v16.154H12L30 53.5z"
      />
      <text x="3" y="13" font-size="11" fill="white">
        Print Name
      </text>
    </g>
  </svg>
);
