import React from 'react';
import { makeStyles } from '@mui/styles';
import ContentLoader from 'react-content-loader';

const useStyles = makeStyles({
  root: {
    background: 'white',
    display: 'block',
    height: 'auto',
    width: '100%',
  },
});

const LINE_COUNT = 40;
const MARGIN_RATIO = 1 / 12;
const LINE_LENGTHS = [0.5, 0.85, 1];

const DocumentPageSkeleton = ({ pageHeight, pageWidth }) => {
  const leftMargin = pageWidth * MARGIN_RATIO;
  const topMargin = pageHeight * MARGIN_RATIO;
  const linePitch = (pageHeight - topMargin * 2) / LINE_COUNT;
  const classes = useStyles();

  return (
    <ContentLoader
      className={classes.root}
      foregroundColor="#ecebeb"
      speed={1}
      viewBox={`0 0 ${pageWidth} ${pageHeight}`}
    >
      {Array.from({ length: LINE_COUNT }, (_, i) => {
        const y = topMargin + i * linePitch;
        const lineWidth =
          LINE_LENGTHS[i % LINE_LENGTHS.length] * pageWidth - 2 * leftMargin;
        const lineHeight = linePitch / 2;

        return (
          <rect
            height={lineHeight}
            key={i}
            rx="3"
            ry="3"
            width={lineWidth}
            x={leftMargin}
            y={y}
          />
        );
      })}
    </ContentLoader>
  );
};

export default DocumentPageSkeleton;
