import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

const QUERY = gql`
  query projectTemplate($id: Int!, $type: String!) {
    projectTemplate(id: $id) {
      id
      studioPlusDirectories {
        id
        directoryId
        fieldId
        value
      }
      studioPlusIntegrationEnabled
      fields(type: $type) {
        id
        displayName
        radioGroupItems {
          name
          value
        }
      }
    }
  }
`;

const useProjectTemplateStudioPlusDirectory = (
  { projectTemplateId },
  options = {},
) => {
  const { error, loading, data, refetch } = useQuery(QUERY, {
    skip: !projectTemplateId,
    // NOTE ProjectTemplate.id is listed in the schema as an ID! type, but the projectTemplate
    // query takes a projectId of type Int! Obviously, this is wrong.
    variables: { id: parseInt(projectTemplateId), type: 'RDO' },
    ...options,
  });
  return {
    error,
    loading,
    data: data?.projectTemplate,
    refetch,
  };
};

export default useProjectTemplateStudioPlusDirectory;
