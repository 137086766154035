import React, { Component } from 'react';

// HoC
import { compose } from 'redux';
import withApi from 'common/hoc/withApi';
import withSnackbarNotification from 'common/hoc/withSnackbarNotification';
import { withRouter } from 'react-router';
import { withStyles } from '@mui/styles';

// Components
import Paper from '@mui/material/Paper';
import _ from 'lodash';
import FailureAlert from 'common/oldJavascripts/components/Shared/FailureAlert';
import Loader from 'common/components/Loader';
import RoleMapper from 'admin/components/RoleMapper';
import Ajax from 'common/utilities/ajax';
import { parseErrorMessages } from 'common/oldJavascripts/utils/errorMessageParser';
import history from 'common/constants/config/history';
import qs from 'qs';

// Utilities
import * as SnackbarVariants from 'common/constants/componentData/snackbarVariants';

const failureAlert = failureMessage => (
  <div
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
    }}
  >
    <FailureAlert queryName={failureMessage} />
  </div>
);

const styles = theme => ({
  root: {
    height: 'calc(100% - 61px)',
    width: '100%',
    overflow: 'auto',
    display: 'grid',
    gridTemplateColumns: '100%',
    gridAutoRows: '100%',
    gridTemplateAreas: `
    "content"
    `,
  },
  content: {
    backgroundColor: theme.palette.common.white,
    gridArea: 'content',
  },
});

class RoleMapperWrapper extends Component {
  static queries = {
    me: {
      info() {
        return {
          id: '/me',
        };
      },
    },
    textTypes: {
      info() {
        return {
          id: '/workflow/fields/text_types',
        };
      },
    },
    systemFields: {
      info(_, related) {
        const { documentId } = related['/router/params'];
        return {
          id: `/workflow/templates/${documentId}/system-fields`,
        };
      },
    },
    template: {
      info(_, related) {
        const { documentId } = related['/router/params'];
        return {
          id: `/workflow/templates/${documentId}`,
          expires: true,
        };
      },
    },
    fields: {
      info(_, related) {
        const { documentId } = related['/router/params'];
        return {
          id: `/workflow/fields?template_id=${documentId}`,
        };
      },
    },
    rules: {
      info(_, related) {
        const { documentId } = related['/router/params'];
        return {
          id: `/workflow/templates/${documentId}/rules`,
        };
      },
    },
    responseBehavior: {
      info() {
        return {
          id: '/workflow/response-behavior-types',
        };
      },
    },
    triggerActions: {
      info() {
        return {
          id: '/workflow/trigger-action-types',
        };
      },
    },
    badgeTypes: {
      info() {
        return {
          id: '/workflow/badge-types',
        };
      },
    },
  };
  static mutators = {
    mapFields: {
      info(_, related) {
        const params = related['/router/params'];
        const documentId = params && params.documentId;
        return {
          id: `/workflow/templates/${documentId}/field_groups/map_fields`,
          create: true,
        };
      },
    },
  };

  state = {
    fields: [],
    rules: [],
    customInputs: [],
    isSaving: false,
    saveSuccess: false,
    template: {},
    imageLoadFailed: false,
    saveDraftIsLoading: false,
  };

  componentWillMount() {
    this.saveCustomInputs = _.debounce(this.saveCustomInputs, 800);
  }

  componentDidMount() {
    this.requestCustomInputs();
    const { fields: fieldsQuery = {}, rules: rulesQuery = {} } = this.props;
    const { status: fieldsStatus } = fieldsQuery || {};
    const { status: rulesStatus } = rulesQuery || {};
    // Reload the fields if the previously requested fields
    // are stale
    if (fieldsStatus === 'success') {
      fieldsQuery.reload();
    }
    if (rulesStatus === 'success') {
      rulesQuery.reload();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      fields: prevFieldsQuery = {},
      rules: prevRulesQuery = {},
    } = prevProps;
    const { fields: fieldsQuery = {}, rules: rulesQuery = {} } = this.props;
    const { status: prevFieldsStatus } = prevFieldsQuery || {};
    const { status: fieldsStatus } = fieldsQuery || {};
    const { status: prevRulesStatus } = prevRulesQuery || {};
    const { status: rulesStatus } = rulesQuery || {};
    const { fields = [], rules = [] } = this.state;
    // Store fields and rules data in state so we can update the data
    // after save draft responses are received
    if (
      prevFieldsStatus === 'loading' &&
      fieldsStatus === 'success' &&
      fields.length === 0
    ) {
      const { data = [] } = fieldsQuery || {};
      this.setState({ fields: Array.isArray(data) ? data : [] });
    }
    if (
      prevRulesStatus === 'loading' &&
      rulesStatus === 'success' &&
      rules.length === 0
    ) {
      const { data = [] } = rulesQuery || {};
      this.setState({ rules: Array.isArray(data) ? data : [] });
    }
  }

  requestCustomInputs = () => {
    const { routerParams: params = {} } = this.props;
    const { documentId } = params;
    return Ajax.get(
      `/workflow/templates/${documentId}/custom_fields`,
    ).then(data => this.setState({ customInputs: data }));
  };

  saveCustomInputs = (customInputs = []) => {
    const { routerParams: params = {} } = this.props;
    const { documentId } = params;
    // remove client generated id's
    const formattedInputs = customInputs.map(input => {
      const updatedInput = { ...input };
      const updatedInputFields = [...updatedInput.inputFields]
        .map(inputField => {
          const updatedInputField = { ...inputField };
          if (`${updatedInputField.name}`.includes('__new-')) {
            delete updatedInputField.name;
          }
          return updatedInputField;
        })
        .filter(updated => !(!updated.name && !updated.active));
      if (`${updatedInput.id}`.includes('__new-')) {
        delete updatedInput.id;
      }
      updatedInput.inputFields = updatedInputFields;
      return updatedInput;
    });
    Ajax.patch(
      `/workflow/templates/${documentId}/custom_fields`,
      { payload: formattedInputs },
      {
        json: true,
      },
    ).then(data => {
      const { systemFields } = this.props;
      this.setState({ customInputs: data });
      systemFields.reload();
    });
  };

  deleteCustomInput = inputId => {
    const { routerParams: params = {} } = this.props;
    const { documentId } = params;
    if (`${inputId}`.includes('__new-')) return;
    Ajax.delete(`/workflow/templates/${documentId}/custom_fields/${inputId}`)
      .then(() => this.requestCustomInputs())
      .then(() => {
        const { systemFields } = this.props;
        systemFields.reload();
      });
  };

  formatDocumentData = data => {
    const { badgeTypes: { data: badgeData = [] } = {} } = this.props;
    const badgeTypes = Array.isArray(badgeData) ? badgeData : [];
    const { fieldGroups = [], openFields = [], rules = [], inputs = [] } = data;
    const formattedFieldGroups = fieldGroups.map(group => {
      const updatedGroup = { ...group };
      if (`${updatedGroup.id}`.includes('__new-')) {
        delete updatedGroup.id;
      }
      if (updatedGroup.delete) {
        updatedGroup.fields = [];
      }
      return updatedGroup;
    });

    const formattedRules = rules.map(rule => {
      let updatedRule = _.cloneDeep(rule);
      // delete the id if it's generated on the client side
      if (
        `${updatedRule.id}`.includes('__new-') ||
        badgeTypes.map(({ name }) => name).includes(`${updatedRule.id}`)
      ) {
        delete updatedRule.id;
      }

      updatedRule.fieldGroups = updatedRule.fieldGroups.map(fieldGroup => {
        const updatedFieldGroup = { ...fieldGroup };
        // delete the id if it's generated on the client side
        if (`${updatedFieldGroup.id}`.includes('__new-')) {
          delete updatedFieldGroup.id;
        }
        return updatedFieldGroup;
      });
      return updatedRule;
    });
    return {
      fieldGroups: formattedFieldGroups,
      openFields,
      rules: formattedRules,
      inputs,
    };
  };

  saveDraft = data => {
    return new Promise(resolve => {
      this.setState({ saveDraftIsLoading: true }, () => {
        const { mapFields } = this.props;
        const { fieldGroups, openFields, rules } = this.formatDocumentData(
          data,
        );
        mapFields.updateAndSave(
          {
            field_groups: fieldGroups,
            open_fields: openFields,
            rules,
            ready: false,
          },
          'edit',
          { json: true },
          ({ fields, rules }) => {
            this.setState({ fields, rules, saveDraftIsLoading: false });
            resolve();
          },
          () => {
            this.setState({ saveDraftIsLoading: false });
            resolve();
          },
        );
      });
    });
  };

  submit = data => {
    this.setState(
      {
        isSaving: true,
      },
      () => {
        const { mapFields } = this.props;
        const { fieldGroups, openFields, rules } = this.formatDocumentData(
          data,
        );
        // save mapped fields
        mapFields.revert('ready').then(() => {
          const { mapFields: updatedMapFieldsMutator } = this.props;
          updatedMapFieldsMutator.updateAndSave(
            {
              field_groups: fieldGroups,
              open_fields: openFields,
              rules,
            },
            'edit',
            { json: true },
            this.successHandler,
            this.errorHandler,
          );
        });
      },
    );
  };

  successHandler = ({ rules, fields }) => {
    const {
      invalidateByPatterns,
      routerParams: { documentId },
      pushSnackbarNotification,
    } = this.props;
    invalidateByPatterns([`/workflow/fields?template_id=${documentId}`]);
    this.setState(
      {
        isSaving: false,
        saveSuccess: true,
        rules,
        fields,
      },
      () =>
        pushSnackbarNotification({
          message: `Document saved successfully.`,
          variant: SnackbarVariants.SUCCESS,
          duration: 7000,
        }),
    );
  };

  errorHandler = res => {
    const message = parseErrorMessages(res);
    this.setState({
      isSaving: false,
    });
    const { pushSnackbarNotification } = this.props;
    pushSnackbarNotification({
      message: `There was an error while saving: ${message}`,
      variant: SnackbarVariants.ERROR,
      duration: 7000,
    });
  };

  _goToLibrary = () => {
    history.push({
      pathname: '/library',
      search: qs.stringify({
        refresh: true,
      }),
    });
  };

  _updateLocalStorage = () => {
    const { template } = this.state;
    if (!_.isEmpty(template)) {
      localStorage.setItem('templateId', template.id);
    }
  };

  getDocumentImageUrls = ({ documentId, page }) => {
    const url = `/workflow/templates/${documentId}`;
    return Ajax.get(url)
      .then(data =>
        data.images.items
          .filter(img => !page || img.page === page)
          .map(({ url }) => url),
      )
      .catch(_err => {
        this.setState({ imageLoadFailed: true });
      });
  };

  render() {
    this._updateLocalStorage();
    const {
      location = {},
      classes,
      systemFields: systemFieldsQuery = {},
      textTypes: textTypesQuery = {},
      template: templateQuery = {},
      fields: fieldsQuery = {},
      responseBehavior: responseBehaviorQuery = {},
      triggerActions: triggerActionsQuery = {},
      rules: rulesQuery = {},
      badgeTypes: badgeTypesQuery = {},
    } = this.props;
    const { fields = [], rules = [] } = this.state;
    const { pathname } = location;
    const { data: systemFields = [] } = systemFieldsQuery || {};
    const { data: textTypes = [] } = textTypesQuery || {};
    const { status: templateStatus, data: template = {} } = templateQuery || {};
    const { status: fieldsStatus } = fieldsQuery || {};
    const {
      status: responseBehaviorStatus,
      data: responseBehaviorOptions = [],
    } = responseBehaviorQuery || {};
    const { status: triggerActionsStatus, data: triggerActions = [] } =
      triggerActionsQuery || {};
    const { status: rulesStatus } = rulesQuery || {};
    const { status: badgeTypesStatus, data: badgeTypes = [] } =
      badgeTypesQuery || {};
    const {
      isSaving,
      templateLoadedTimes,
      imageLoadFailed,
      saveSuccess,
      customInputs,
      saveDraftIsLoading,
    } = this.state;
    const loadingStatuses = [
      rulesStatus,
      fieldsStatus,
      templateStatus,
      responseBehaviorStatus,
      triggerActionsStatus,
      badgeTypesStatus,
    ];

    if (loadingStatuses.some(status => status === 'loading')) return <Loader />;

    if (imageLoadFailed) {
      return failureAlert('the document images');
    }

    if (fieldsStatus === 'failed' || templateStatus === 'failed') {
      return failureAlert('the document');
    }

    if (
      fieldsStatus === 'success' &&
      templateStatus === 'success' &&
      !template.images
    ) {
      this.requestTemplate();
      return <Loader />;
    }

    if (fieldsStatus === 'success' && templateStatus === 'success') {
      const { fieldGroups, images, name: title } = template;
      const { items: docImages = [] } = images;
      if (docImages.length === 0) {
        return failureAlert('the document images');
      }

      const rolesProp = fieldGroups.map((fg, index) => ({
        name: fg.name,
        id: fg.id,
        fieldsMapped: 0,
        isDefault: fg.initial,
      }));
      const fieldsProp = Array.isArray(fields) ? fields : [];
      const documentProp = {
        id: template.id,
        name: template.name,
        pages: docImages.length,
        height: images.height,
        width: images.width,
        fieldsMapped: template.fieldsMapped,
        fieldGroups: template.fieldGroups,
        images: docImages,
        fields: fieldsProp,
      };
      const replacementStatus = pathname.includes('replacement');
      const systemFieldsProp = Array.isArray(systemFields) ? systemFields : [];
      const textTypesProp = Array.isArray(textTypes) ? textTypes : [];
      const responseBehaviorProp = Array.isArray(responseBehaviorOptions)
        ? responseBehaviorOptions
        : [];
      const triggerActionsProp = Array.isArray(triggerActions)
        ? triggerActions
        : [];
      const rulesProps = Array.isArray(rules) ? rules : [];
      const badgeProps = Array.isArray(badgeTypes) ? badgeTypes : [];
      return (
        <div className={classes.root}>
          <Paper className={classes.content}>
            <RoleMapper
              title={title}
              roles={rolesProp}
              groups={rolesProp}
              doc={documentProp}
              documents={[documentProp]}
              submitGroupMappings={this.submit}
              saveDraft={this.saveDraft}
              textFieldOptions={textTypesProp}
              systemFieldOptions={systemFieldsProp}
              isSaving={isSaving}
              saveSuccess={saveSuccess}
              isReplacement={replacementStatus}
              replacement={replacementStatus}
              key={templateLoadedTimes}
              getDocumentImageUrls={this.getDocumentImageUrls}
              rules={rulesProps}
              saveCustomInputs={this.saveCustomInputs}
              inputs={customInputs}
              deleteCustomInput={this.deleteCustomInput}
              responseBehaviorOptions={responseBehaviorProp}
              triggerActions={triggerActionsProp}
              saveDraftIsLoading={saveDraftIsLoading}
              badgeTypes={badgeProps}
            />
          </Paper>
        </div>
      );
    }
  }
}

export default compose(
  withApi,
  withRouter,
  withSnackbarNotification,
  withStyles(styles),
)(RoleMapperWrapper);
