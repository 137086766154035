import withApi from 'common/hoc/withApi';
import React, { Component } from 'react';
import { compose } from 'redux';
import { withStyles } from '@mui/styles';
import DocumentSigner from 'packages/react-document-signer/DocumentSigner';
import Loader from 'common/components/Loader';
import FailureAlert from '../FailureAlert';
import ajax from 'common/utilities/ajax.js';
import UsaOnly from 'common/components/UsaOnly';
// Context
import { FeatureFlagsContext } from 'common/context';

const styles = {
  loader: {
    gridArea: 'content',
  },
};

class DocumentSignerContainer extends Component {
  static contextType = FeatureFlagsContext;

  static queries = {
    documents: {
      info: function(params, related) {
        const { projectId, offerId: routerOfferId } = related['/router/params'];
        const { type, offerId: paramsOfferId } = params;
        const offerId = routerOfferId || paramsOfferId;

        switch (type) {
          case 'approver':
            return {
              id: `/v2/projects/${projectId}/offers/${offerId}/document-details`,
            };
          case 'verifier':
            return {
              id: `/v2/projects/${projectId}/i9-verifications/${offerId}/document-details`,
              expires: true,
            };
          default:
            return {
              id: `/v2/offers/${offerId}/documents-details`,
            };
        }
      },
    },
    profile: {
      info: function() {
        return {
          id: `/account/profile?verified=true`,
        };
      },
    },
    me: {
      info() {
        return { id: '/me' };
      },
    },
  };
  state = {
    imagesFailed: false,
    templatesAcknowledgeTitle: '',
    templatesAcknowledgeNotes: '',
    removeDocumentId: '',
  };
  componentDidMount() {
    const { offer: { project_id: projectId } = {} } = this.props;
    if (!projectId) {
      return;
    }
    ajax
      .get(`/projects/${projectId}`)
      .then(({ templates_acknowledge_notes, templates_acknowledge_title }) =>
        this.setState({
          templatesAcknowledgeNotes: templates_acknowledge_notes,
          templatesAcknowledgeTitle: templates_acknowledge_title,
        }),
      );
  }
  reloadDocuments = reloadDocumentDetails => {
    const { documents: documentsQuery = {} } = this.props;
    const { reload } = documentsQuery;

    if (reloadDocumentDetails) {
      return reload();
    }
  };
  componentDidUpdate(prevProps) {
    const {
      documents: documentsQuery = {},
      handleEndDocumentInteraction,
      reloadDocumentDetails,
    } = this.props;
    const { data = {} } = documentsQuery;
    const { items: documents = [] } = data;
    const readyDocs = documents.find(doc => doc.ready);

    if (!readyDocs) {
      handleEndDocumentInteraction(true);
      return;
    }
    if (prevProps.documents.data.items.length !== documents.length) {
      const newDocs = documents.filter(doc => doc.ready && !doc.signed);
      if (documents.length === 0 || newDocs.length === 0) {
        this.reloadDocuments(reloadDocumentDetails);
        handleEndDocumentInteraction(true);
      }
    }
    if (prevProps.reloadDocumentDetails !== this.props.reloadDocumentDetails) {
      this.reloadDocuments(reloadDocumentDetails);
    }
  }

  handleOfferAccept = docs => {
    const { params } = this.props;
    const { offerId } = params;
    const { handleOfferAccept: upstreamHandleOfferAccept } = this.props;
    const { documents: documentsQuery = {} } = this.props;
    const { data = {} } = documentsQuery;
    const { items: documents = [] } = data;
    const newDocs = {};
    docs
      .filter(doc => doc.ready && !doc.signed)
      .forEach(doc => (newDocs[doc.id] = doc));
    return upstreamHandleOfferAccept(newDocs, offerId, documents);
  };
  getDocumentImageUrls = ({ documentId, page }) => {
    // Crew Member: offerId exists in routerParams
    // Approver, I9 Verifier: offerId exists in params
    this.setState({ removeDocumentId: documentId });
    const {
      routerParams: { offerId: routerOfferId } = {},
      params: { offerId: paramsOfferId } = {},
    } = this.props;
    const offerId = routerOfferId || paramsOfferId;

    const ajaxQuery = page ? `?page=${page}` : '';
    const ajaxUrl = `/v2/offers/${offerId}/documents/${documentId}/images${ajaxQuery}`;
    return ajax
      .get(ajaxUrl)
      .then(({ items }) => {
        return items.map(({ url }) => url);
      })
      .catch(this.throwFatalError);
  };
  throwFatalError = () => {
    this.setState({ imagesFailed: true });
  };

  rejectI9FlagOn = () => {
    const featureFlags = this.context || [];
    const rejectI9FlagOn = featureFlags.includes('RejectI9');
    return rejectI9FlagOn;
  };

  _handleOfferReject = () => {
    const { handleOfferReject, documents: documentsQuery = {} } = this.props;
    const { data = {} } = documentsQuery;
    const { items: documents = [] } = data;
    const documentId = documents.length > 0 ? documents[0]?.id : '';
    const rejectI9FlagOn = this.rejectI9FlagOn();
    if (rejectI9FlagOn) {
      return handleOfferReject(documentId);
    } else {
      handleOfferReject(false);
    }
  };

  _handleRemoveDocument = () => {
    const { removeDocumentId } = this.state;
    const { handleRemoveDocument } = this.props;
    handleRemoveDocument(removeDocumentId);
  };

  render() {
    const {
      classes = {},
      documents = {},
      profile = {},
      me,
      handleSave,
      filter = false,
      params,
      className,
      canApproveOffers,
    } = this.props;
    const { type = 'approver' } = params;
    const {
      imagesFailed,
      templatesAcknowledgeTitle,
      templatesAcknowledgeNotes,
    } = this.state;
    if (documents.status === 'loading' || profile.status === 'loading') {
      return <Loader className={classes.loader} />;
    }

    if (imagesFailed || documents.status === 'failed')
      return (
        <FailureAlert
          className={className}
          queryName="the Offer Document Details"
        />
      );

    const { items: documentData = [] } = documents.data;
    const { first_name, last_name, middle_name } = profile.data;

    let filteredDocs = [...documentData];

    if (filter) filteredDocs = filteredDocs.filter(filter);

    // We have to update the document data because of discrepencies
    // between what the documentation outlined and what the backend sends
    const updatedDocs = filteredDocs.map(doc => ({
      ...doc,
      // source: images[doc.id],
      fieldMappings: doc.fields,
      name: doc.templateName,
      rank: 1,
    }));
    const isDocOne = updatedDocs.length === 1;
    const readyDocs = updatedDocs.find(doc => doc.ready);

    if (!readyDocs) {
      return <Loader className={classes.loader} />;
    }
    const name = [first_name, middle_name, last_name].filter(s => s).join(' ');
    const initials = [first_name, middle_name, last_name]
      .filter(s => s)
      .map(s => String(s)[0])
      .join('');
    const actor = { name, initials };
    if (filteredDocs.length === 0) return <div />;

    const buttonLabels = (userType => {
      switch (userType) {
        case 'approver':
          return {
            submit: 'Approve Offer',
            cancel: 'Reject Offer',
            save: 'Save Offer',
          };
        case 'verifier':
          return {
            submit: 'Verify I-9',
            cancel: this.rejectI9FlagOn() ? 'Reject I-9' : 'Cancel',
            save: 'Save I-9',
          };
        default:
          return {
            submit: 'Accept Offer',
            cancel: 'Reject Offer',
            save: 'Save Offer',
          };
      }
    })(type);

    const actions = {
      submit: {
        text: buttonLabels.submit,
        action: this.handleOfferAccept,
      },
      cancel: {
        text: buttonLabels.cancel,
        action: this._handleOfferReject,
      },
      remove: {
        action: this._handleRemoveDocument,
      },
    };
    if (handleSave) {
      actions.save = {
        text: buttonLabels.save,
        action: handleSave,
      };
    }

    return (
      <UsaOnly>
        {isUsa => (
          <DocumentSigner
            className={className}
            documents={updatedDocs}
            actor={actor}
            getDocumentImageUrls={this.getDocumentImageUrls}
            throwFatalError={this.throwFatalError}
            actions={actions}
            canApproveOffers={canApproveOffers}
            templatesAcknowledgeNotes={templatesAcknowledgeNotes}
            isDocOne={isDocOne}
            templatesAcknowledgeTitle={templatesAcknowledgeTitle}
            me={me}
            isSaveSignatureEnabled
            showI9Note={isUsa}
          />
        )}
      </UsaOnly>
    );
  }
}

export default compose(withApi, withStyles(styles))(DocumentSignerContainer);
