import ReactDatePicker from 'react-datepicker';
import CustomDateInput from './CustomDatePickerInput';
import React from 'react';
import moment from 'moment';
import { withStyles } from '@mui/styles';
import { PageInputStatus } from '../../types';
import useStatusClass_1 from './useStatusClass';
import 'react-datepicker/dist/react-datepicker.css';

const styles = theme => ({
  root: {
    border: 0,
    color: 'black',
    height: '100%',
    padding: 0,
    paddingLeft: '1%',
    width: '100%',
  },
  input: {
    color: 'black',
  },
});

const style = styles();

const DEFAULT_DATE = '12/12/1990';

const getValidMoment = (value, defaultValue, format) => {
  if (!value) return null;
  if (value === defaultValue) return value;
  const m = moment(value, format);
  if (!m.isValid()) return null;
  return m;
};

const getMomentValue = (value, format) => {
  return value && value.isValid && value.isValid()
    ? value.format(format)
    : value;
};

const DocumentPageDateInput = function(_a) {
  const { onChange, onFocus, input } = _a;
  const { id, status, value, dateFormat } = input;

  const dateFormatter = dateFormat ? dateFormat.toUpperCase() : 'MM/DD/yy';
  const isDisabled = status === PageInputStatus.Disabled;
  const statusClass = useStatusClass_1(status);
  const className = `${statusClass} ${style.root} ${style.input}`;

  const handleChange = v => {
    const m = getValidMoment(v, '', dateFormatter);
    const formattedValue = (m && m.format && m.format(dateFormatter)) || m;
    onChange(formattedValue);
  };

  const filteredProps = {
    ...input,
    fieldId: id,
    onFocus,
    statusClass,
    dateFormatter,
  };

  const validMoment = getValidMoment(value, DEFAULT_DATE, dateFormatter);

  return isDisabled ? (
    <div className={className}>
      {value ? getMomentValue(validMoment, dateFormatter) : ''}
    </div>
  ) : (
    <ReactDatePicker
      customInput={<CustomDateInput {...filteredProps} />}
      onChange={v => handleChange(v)}
      placeholderText={dateFormat ? dateFormat : 'Click to select a date'}
      selected={(typeof validMoment === 'object' && validMoment) || null}
      value={getMomentValue(validMoment, dateFormatter)}
      className={className}
      dateFormat={dateFormatter}
      onFocus={() => onFocus()}
      popperModifiers={{
        preventOverflow: {
          boundariesElement: 'scrollParent',
        },
      }}
    />
  );
};

export default withStyles(styles)(DocumentPageDateInput);
