import React from 'react';
import { makeStyles } from '@mui/styles';
import useStatusClass from './useStatusClass';
import { PageInputStatus } from '../../types';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
  },
  input: {
    height: '70%',
    margin: '15%',
    padding: 0,
    width: '70%',
  },
});

const DocumentPageCheckboxInput = ({ onChange, onFocus, input }) => {
  const { id, status, value } = input;
  const classes = useStyles();
  const statusClass = useStatusClass(status);

  return (
    <div className={`${statusClass} ${classes.root}`}>
      <input
        type="checkbox"
        className={classes.input}
        data-tid={`DocumentPageCheckboxInput-input-${id}`}
        disabled={status === PageInputStatus.Disabled}
        onChange={e => onChange(e.target.checked)}
        onFocus={() => onFocus()}
        checked={value !== null && value !== undefined ? value : false}
      />
    </div>
  );
};

export default DocumentPageCheckboxInput;
