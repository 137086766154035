import { makeStyles } from '@mui/styles';
import { PageInputStatus } from '../../types';

// WORKAROUND:
// It would be better to reduce the opacity of these colors to allow some of the
// form background to bleed through & make changing the brightness simpler,
// however there is an obscure bug in safari that means you cannot use opacity
// inside an SVG foreignObject element. See link:
// https://bugs.webkit.org/show_bug.cgi?id=23113

const useStyles = makeStyles({
  disabled: {
    backgroundColor: '#fff',
  },
  error: {
    backgroundColor: '#FFDCDC',
    '&:focus-within': {
      backgroundColor: '#FFB8B8',
    },
  },
  optional: {
    backgroundColor: '#AEF8FF',
    '&:focus-within': {
      backgroundColor: '#5CF5FF',
    },
  },
  required: {
    backgroundColor: '#FBFFAE',
    '&:focus-within': {
      backgroundColor: '#FBFF5C',
    },
  },
  success: {
    backgroundColor: '#C7FFAE',
    '&:focus-within': {
      backgroundColor: '#90FF5C',
    },
  },
});

const useStatusClass = status => {
  const classes = useStyles();
  switch (status) {
    case PageInputStatus.Disabled:
      return classes.disabled;
    case PageInputStatus.Error:
      return classes.error;
    case PageInputStatus.Optional:
      return classes.optional;
    case PageInputStatus.Required:
      return classes.required;
    case PageInputStatus.Success:
      return classes.success;
    default:
      return '';
  }
};

export default useStatusClass;
