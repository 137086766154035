import React from 'react';
import { makeStyles } from '@mui/styles';
import useStatusClass from './useStatusClass';
import { PageInputStatus } from '../../types';

const useStyles = makeStyles({
  root: {
    color: 'black',
    height: '100%',
    width: '100%',
    border: 0,
  },
});

const DocumentPageSelectInput = ({ onChange, onFocus, input }) => {
  const classes = useStyles();
  const statusClass = useStatusClass(input.status);
  const options = input.options.sort((a, b) =>
    a.description.localeCompare(b.description),
  );

  return (
    <select
      className={`${statusClass} ${classes.root}`}
      data-tid={`DocumentPageSelectInput-select-${input.id}`}
      disabled={input.status === PageInputStatus.Disabled}
      onChange={e => onChange(e.target.value)}
      onFocus={() => onFocus()}
      value={
        input.value !== null && input.value !== undefined ? input.value : ''
      }
    >
      <option value="">---</option>
      {options.map(({ value, description }) => (
        <option key={value} value={value}>
          {description}
        </option>
      ))}
    </select>
  );
};

export default DocumentPageSelectInput;
