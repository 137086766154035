import React, { useState } from 'react';
import { withStyles } from '@mui/styles';
import {
  Dialog,
  Grid,
  IconButton,
  Button,
  Typography,
  ThemeProvider,
} from '@mui/material';
import {
  EditOutlined as EditIcon,
  DeleteOutlined as DeleteIcon,
  CloseSharp as CloseIcon,
} from '@mui/icons-material';
import StudioPlusFolderSelectorModal from './StudioPlusFolderSelectorModal';
import ConditionSelector from './ConditionSelector';
import { documentSignerTheme } from 'common/shared/oldDocumentSignerUI/theme';

const styles = theme => ({
  listStyle: {
    listStyleType: 'disc',
    paddingLeft: 30,
  },
  deleteDialogPaper: {
    padding: 18,
    minHeight: '120px',
    height: 'max-content',
    width: 400,
    maxHeight: '200',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  closeIcon: {
    cursor: 'pointer',
  },
  cancelButton: {
    marginTop: theme.spacing(1) + ' !important',
    marginLeft: theme.spacing(1) + ' !important',
    padding: '5px !important',
  },
  deleteButton: {
    marginTop: theme.spacing(1) + ' !important',
    marginLeft: theme.spacing(1) + ' !important',
    color: '#fff !important',
    backgroundColor: '#ea5959 !important',
    border: 'none !important',
    '&:hover': {
      backgroundColor: '#ff7f7f',
    },
    '&:disabled': {
      backgroundColor: '#9A9FAF',
    },
  },
});

const StudioPlusFolderList = props => {
  const {
    directories,
    selectedDirectories,
    onChange,
    setIsEnabled,
    projectTemplateId,
    templateFields,
    projectId,
    classes,
  } = props;
  const [currentModalDirecoryId, setCurrentModalDirecoryId] = useState(null);
  const [selectedDirectoryIndex, setSelectedDirectoryIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [alertModalOpen, setAlertModalOpen] = useState(false);

  // Given array of directories & a directoryId, returns the path to the directory
  // or null if the directory is not found.
  const getDirectoryPath = (directories, directoryId, path = '') => {
    // This is a bit clunky
    let p = null;
    directories.find(({ id, children, name }) => {
      const thisPath = `${path}/${name}`;
      if (id === directoryId) {
        p = thisPath;
        return true;
      }
      if (!(children && children.length)) return false;
      p = getDirectoryPath(children, directoryId, thisPath);
      return !!p;
    });
    return p;
  };

  const editDirectory = (directoryId, index) => {
    setCurrentModalDirecoryId(directoryId);
    setSelectedDirectoryIndex(index);
  };

  const deleteDirectory = () => {
    const updatedDirectories = [...selectedDirectories];
    updatedDirectories.splice(selectedDirectoryIndex, 1);
    if (updatedDirectories?.length === 0) {
      setIsEnabled(false);
    }
    onChange(updatedDirectories);
    setAlertModalOpen(false);
  };

  const changeSelectedDirectory = directory => {
    const updatedDirectories = [...selectedDirectories];
    updatedDirectories[selectedDirectoryIndex].directoryId = directory;
    onChange(updatedDirectories);
  };

  const onChangeCondition = (index, fieldId, value) => {
    const updatedDirectories = [...selectedDirectories];
    updatedDirectories[index].fieldId = fieldId;
    updatedDirectories[index].value = value;
    onChange(updatedDirectories);
  };

  return (
    <ThemeProvider theme={documentSignerTheme}>
      <ul className={classes.listStyle}>
        {selectedDirectories.map(({ directoryId, fieldId, value }, index) => (
          <li key={index}>
            <Grid container direction="row" alignItems="center">
              <Grid item>
                <Grid container direction="row" alignItems="center">
                  <Typography
                    data-test-id={`StudioPlusFolderSelector-path-${index}`}
                  >
                    {getDirectoryPath(directories, directoryId)}
                  </Typography>
                  <IconButton
                    data-test-id={`StudioPlusFolderSelector-edit-${index}`}
                    onClick={() => {
                      editDirectory(directoryId, index);
                      setModalOpen(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    data-test-id={`StudioPlusFolderSelector-delete-${index}`}
                    onClick={() => {
                      setSelectedDirectoryIndex(index);
                      setCurrentModalDirecoryId(directoryId);
                      setAlertModalOpen(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid item>
                <ConditionSelector
                  projectId={projectId}
                  directoryId={directoryId}
                  templateId={projectTemplateId}
                  fieldId={fieldId}
                  value={value}
                  templateFields={templateFields}
                  directoryIndex={index}
                  onChange={onChangeCondition}
                />
              </Grid>
            </Grid>
          </li>
        ))}
      </ul>
      <StudioPlusFolderSelectorModal
        directories={directories}
        directoryId={currentModalDirecoryId}
        open={modalOpen}
        onSelect={changeSelectedDirectory}
        onClose={() => {
          setModalOpen(false);
          setCurrentModalDirecoryId(null);
          setSelectedDirectoryIndex(null);
        }}
      />
      <Dialog
        open={alertModalOpen}
        maxWidth="xs"
        classes={{ paper: classes.deleteDialogPaper }}
      >
        <Grid item>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="h5">Delete Studio+ Destination</Typography>
            <CloseIcon
              className={classes.closeIcon}
              onClick={() => setAlertModalOpen(false)}
            />
          </Grid>
          <Typography
            variant="body1"
            data-test-id={'StudioPlusDestination-Deletion-Confirmation'}
          >
            Are you sure you want to delete the Studio+ destination?
          </Typography>
        </Grid>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            variant="outlined"
            className={classes.cancelButton}
            onClick={() => setAlertModalOpen(false)}
          >
            Cancel
          </Button>
          <Button
            variant="filled"
            className={classes.deleteButton}
            onClick={deleteDirectory}
            data-test-id={'StudioPlusDestination-Delete-button'}
          >
            Delete
          </Button>
        </Grid>
      </Dialog>
    </ThemeProvider>
  );
};

export default withStyles(styles)(StudioPlusFolderList);
