import React from 'react';
import { makeStyles } from '@mui/styles';
import useStatusClass from './useStatusClass';
import { PageInputStatus } from '../../types';

const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
  },
  input: {
    height: '80%',
    margin: '10%',
    padding: 0,
    width: '80%',
  },
});

const DocumentPageRadioInput = ({ onChange, onFocus, input }) => {
  const { id, status, value } = input;
  const classes = useStyles();
  const statusClass = useStatusClass(status);

  return (
    <div className={`${statusClass} ${classes.root}`}>
      <input
        className={classes.input}
        data-tid={`DocumentPageRadioInput-input-${id}`}
        onChange={() => onChange(true)}
        onFocus={() => onFocus()}
        checked={value !== null && value !== undefined ? value : false}
        disabled={status === PageInputStatus.Disabled}
        type="radio"
      />
    </div>
  );
};

export default DocumentPageRadioInput;
